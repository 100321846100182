import { Label, SectionMain } from 'components/Shared'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { CSSProperties, FC } from 'react'
import { Microsite_SubpageBodyVideos_ListFields } from 'types/graphql'
import { getHeightInPercentageOfWidth } from 'utils'
import cn from 'classnames'
import { PrismicEmbed } from 'types/util'
import type { PropsWithChildren } from 'react'

interface Props {
  fields: Microsite_SubpageBodyVideos_ListFields[]
}

export const VideosList: FC<PropsWithChildren<Props>> = ({ fields }) => {
  const getNumberLabel = (index: number) => {
    const formatNumber = (number: number) =>
      number.toLocaleString(undefined, {
        minimumIntegerDigits: 2,
      })

    return `${formatNumber(index + 1)}/${formatNumber(fields.length)}`
  }

  return (
    <SectionMain noPaddingX className="md:px-15 lg:px-80">
      <div className="space-y-64 lg:space-y-200">
        {fields.map(
          (
            {
              video,
              label,
              title,
              description,
            }: {
              video?: PrismicEmbed
              label?: RichTextBlock[]
              title?: RichTextBlock[]
              description?: RichTextBlock[]
            },
            index: number
          ) => {
            const isLeft = index % 2 === 0

            return (
              <div
                key={index}
                className={cn(
                  { 'mr-15 md:items-start': isLeft, 'ml-15 md:items-end': !isLeft },
                  'md:mx-0 md:flex md:flex-col'
                )}
              >
                {video?.html && (
                  <div className="-mb-2 w-full max-w-1000 space-y-16 lg:space-y-32">
                    <Label
                      small
                      className={cn('w-fit-content leading-1.6 text-tundora', {
                        'ml-auto': isLeft,
                      })}
                    >
                      {getNumberLabel(index)}
                    </Label>
                    <div
                      className={cn('relative', {
                        'md:pl-24 lg:pl-64': isLeft,
                        'md:pr-24 lg:pr-64': !isLeft,
                      })}
                    >
                      <div
                        className={cn(
                          'video-bg-container absolute bottom-0 hidden w-full bg-black100 md:block',
                          {
                            'left-0': isLeft,
                            'right-0': !isLeft,
                          }
                        )}
                        style={{ '--padding-md': '24px', '--padding-lg': '64px' } as CSSProperties}
                      />
                      <div
                        className="video-embed"
                        style={{
                          paddingBottom: getHeightInPercentageOfWidth(video.width, video.height),
                        }}
                        dangerouslySetInnerHTML={{ __html: video.html }}
                      />
                    </div>
                  </div>
                )}
                <div className={cn('bg-black100 p-24 pt-32 text-white md:w-700 lg:p-64 lg:pt-40')}>
                  <Label small className="mb-24 leading-1.6">
                    <RichText render={label} />
                  </Label>
                  <div className="mb-16 text-26 leading-9 lg:text-32 lg:leading-1.3">
                    <RichText render={title} />
                  </div>
                  <div className="leading-1.6">
                    <RichText render={description} />
                  </div>
                </div>
              </div>
            )
          }
        )}
      </div>
    </SectionMain>
  )
}
