import { FC } from 'react'
import {
  ServiceBodyAccordion_With_ImageFields,
  ServiceBodyAccordion_With_ImagePrimary,
} from '../types/graphql'
import Fade from 'react-reveal'
import { Label, SectionMain } from '../components/Shared'
import { AccordionItem } from 'components/Shared/AccordionItem'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import Image from 'next/image'
import type { PropsWithChildren } from 'react'

interface Props {
  primary: ServiceBodyAccordion_With_ImagePrimary
  fields: ServiceBodyAccordion_With_ImageFields[]
}
export const AccordionWithImage: FC<PropsWithChildren<Props>> = ({ primary, fields }) => {
  return (
    <SectionMain className="overflow-hidden">
      <Fade>
        <div className="flex flex-col">
          <div className="flex flex-shrink-0 flex-col-reverse items-center lg:flex-row lg:justify-between">
            <div className="mb-48 w-full flex-1 md:min-w-450 lg:max-w-3/4 lg:max-w-500">
              <Label
                className="w-screen py-20 pr-32 md:pb-40 md:pt-0 lg:w-auto lg:pr-0"
                newLineAferColon={true}
              >
                {primary.label}
              </Label>
              <ul className="w-full lg:max-w-580">
                {fields.map((item, i) => (
                  <AccordionItem
                    key={i}
                    number={i}
                    claim={item.claim}
                    heading={item.heading}
                    isLastItem={fields.length === i + 1}
                  />
                ))}
              </ul>
            </div>
            <div className="mx-auto w-full max-w-9/10 translate-x-16/100 transform lg:mt-70 lg:max-w-700 xl:translate-x-200">
              <ParallaxWrapper breakpoint="lg" deflection={-40} factor={0.3}>
                <Image
                  src={primary.image?.url}
                  alt={primary.image?.alt}
                  width={700}
                  height={700}
                  layout="responsive"
                  objectFit="contain"
                />
              </ParallaxWrapper>
            </div>
          </div>
        </div>
      </Fade>
    </SectionMain>
  )
}
