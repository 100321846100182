import {
  ArticleBodyArticle_ContentPrimary,
  ArticleBodyCustom_SubscriptionPrimary,
  Case_StudyBodyClaims_With_BgPrimary,
  Case_StudyBodyClaims_With_ImagePrimary,
  Case_StudyBodyCs_HeroPrimary,
  Case_StudyBodyLogosFields,
  Case_StudyBodyLogosPrimary,
  Case_StudyBodyNumbersPrimary,
  Case_StudyBodyQuotePrimary,
  Case_StudyConnectionEdge,
  Job_OfferConnectionEdge,
  Job_Offer,
  Microsite_Landing_PageBodyArticles_HeadingPrimary,
  Microsite_Landing_PageBodyMicrosite_Landing_Page_HeroPrimary,
  Microsite_Landing_PageBodyTestimonyPrimary,
  Microsite_SubpageBodyMicrosite_Subpage_HeroPrimary,
  Microsite_SubpageBodyTilesPrimary,
  Microsite_SubpageBodyVideos_ListFields,
  PageBodyCentered_TextPrimary,
  PageBodyClaims_With_AnimationFields,
  PageBodyClaims_With_AnimationPrimary,
  PageBodyCta_Banner_With_ImagePrimary,
  PageBodyCta_Claims_With_ImageFields,
  PageBodyCta_Claims_With_ImagePrimary,
  PageBodyCta_Heading_With_ImagePrimary,
  PageBodyGrid_TextsFields,
  PageBodyGrid_TextsPrimary,
  PageBodyHeading_With_ImagesFields,
  PageBodyHeading_With_ImagesPrimary,
  PageBodyHero,
  PageBodyIconsFields,
  PageBodyImagePrimary,
  PageBodyLarge_HeadingPrimary,
  PageBodyListPrimary,
  PageBodyNumbersFields,
  PageBodyPartnershipFields,
  PageBodyPartnershipPrimary,
  PageBodyPhasesFields,
  PageBodyPhasesPrimary,
  PageBodySpacerPrimary,
  PageBodyStoryFields,
  PageBodyStoryPrimary,
  PageBodyTeam_BigPrimary,
  PageBodyTeam_SmallPrimary,
  PageBodyTestimonyPrimary,
  PageBodyText_ColumnsFields,
  PageBodyText_ColumnsPrimary,
  ServiceBodyAccordion_With_HeadingFields,
  ServiceBodyAccordion_With_HeadingPrimary,
  ServiceBodyAccordion_With_ImageFields,
  ServiceBodyAccordion_With_ImagePrimary,
  ServiceBodyHeading_With_ColumnsFields,
  ServiceBodyHeading_With_ColumnsPrimary,
  ServiceBodyPerson_SlicePrimary,
  ServiceBodyPicture_ColumnsFields,
  ServiceBodyPicture_ColumnsPrimary,
  ServiceConnectionEdge,
  Service,
  TeamRoles,
} from 'types/graphql'
import {
  AccordionWithHeading,
  AccordionWithImage,
  ArticleContent,
  ArticlesHeading,
  CenteredText,
  ClaimsWithAnimation,
  ClaimsWithBg,
  ClaimsWithImage,
  CtaClaimsWithImage,
  CtaHeadingWithImage,
  CtaBannerWithImage,
  GridTexts,
  HeadingWithColumns,
  HeadingWithImages,
  Hero,
  HeroCaseStudy,
  Icons,
  Image,
  LargeHeading,
  List,
  Logos,
  MicrositeLandingHero,
  MicrositeSubpageHero,
  Numbers,
  Partnership,
  Phases,
  PictureColumns,
  Quote,
  Spacer,
  Story,
  TeamBig,
  TeamSmall,
  Testimony,
  TextColumns,
  Tiles,
  TilesField,
  VideosList,
} from 'slices'
import { PersonSlice, PersonSliceProps } from 'components/PersonSlice/PersonSlice'
import { SubscribeNewsletter } from 'components/SubscribeNewsletter/SubscribeNewsletter'
import { MicrositeLinks } from 'types/util'
import { FC } from 'react'
import { CaseStudiesSlider } from 'slices/CaseStudiesSlider'
import type { PropsWithChildren } from 'react'

interface Props {
  exitLabel: string
  skillsLabel: string
  slices: any
  team?: TeamRoles[]
  services?: ServiceConnectionEdge[]
  jobOffers?: Job_OfferConnectionEdge[]
  micrositeSubpages?: MicrositeLinks
  caseStudies?: Case_StudyConnectionEdge[]
}

export const SliceZone: FC<PropsWithChildren<Props>> = ({
  exitLabel,
  skillsLabel,
  slices,
  team,
  jobOffers,
  micrositeSubpages,
  caseStudies,
}) => {
  if (!slices?.length) return null

  return (
    // use slice type to determine the component
    slices.map((slice: any, i: number) => {
      switch (slice?.type) {
        case 'hero':
          return <Hero key={i} data={slice as PageBodyHero} />
        case 'cs_hero':
          return (
            <HeroCaseStudy
              key={i}
              exitLabel={exitLabel}
              skillsLabel={skillsLabel}
              data={slice.primary as Case_StudyBodyCs_HeroPrimary}
            />
          )
        case 'claims_with_image':
          return (
            <ClaimsWithImage
              key={i}
              data={slice.primary as Case_StudyBodyClaims_With_ImagePrimary}
            />
          )
        case 'cta_claims_with_image':
          return (
            <CtaClaimsWithImage
              key={i}
              primary={slice.primary as PageBodyCta_Claims_With_ImagePrimary}
              fields={slice.fields as PageBodyCta_Claims_With_ImageFields[]}
            />
          )
        case 'centered_text':
          return <CenteredText key={i} data={slice.primary as PageBodyCentered_TextPrimary} />
        case 'image':
          return <Image key={i} data={slice.primary as PageBodyImagePrimary} />
        case 'icons':
          return <Icons key={i} data={slice.fields as PageBodyIconsFields[]} />
        case 'claims_with_bg':
          return (
            <ClaimsWithBg key={i} data={slice.primary as Case_StudyBodyClaims_With_BgPrimary} />
          )
        case 'numbers':
          return (
            <Numbers
              key={i}
              primary={slice.primary as Case_StudyBodyNumbersPrimary | undefined}
              fields={slice.fields as PageBodyNumbersFields[]}
              isLast={i === slices.length - 1}
            />
          )
        case 'list': {
          let jobsData = jobOffers

          if (slice.fields?.[0].job_offer) {
            jobsData = slice.fields.map((field: { service: Service; job_offer: Job_Offer }) => {
              // returning { node:  } to get same data structure as Job_OfferConnectionEdge in jobOffers
              return { node: field.job_offer }
            })
          }

          const dataSwitch = () => {
            switch (slice.primary.listType) {
              case 'services':
                return slice.fields
              case 'jobs':
                return jobsData
              case 'microsite':
                return micrositeSubpages
            }
          }
          return (
            <List
              key={i}
              data={slice.primary as PageBodyListPrimary}
              listType={slice.primary.listType}
              listData={dataSwitch()!}
            />
          )
        }
        case 'team_small':
          return team ? (
            <TeamSmall key={i} data={slice.primary as PageBodyTeam_SmallPrimary} team={team} />
          ) : null
        case 'team_big':
          return team ? (
            <TeamBig key={i} data={slice.primary as PageBodyTeam_BigPrimary} team={team} />
          ) : null
        case 'testimony':
          return (
            <Testimony
              key={i}
              data={
                slice.primary as Microsite_Landing_PageBodyTestimonyPrimary &
                  PageBodyTestimonyPrimary
              }
            />
          )
        case 'quote':
          return <Quote key={i} data={slice.primary as Case_StudyBodyQuotePrimary} />
        case 'phases':
          return (
            <Phases
              key={i}
              primary={slice.primary as PageBodyPhasesPrimary}
              fields={slice.fields as PageBodyPhasesFields[]}
            />
          )
        case 'grid_texts':
          return (
            <GridTexts
              key={i}
              primary={slice.primary as PageBodyGrid_TextsPrimary}
              fields={slice.fields as PageBodyGrid_TextsFields[]}
            />
          )
        case 'cta_heading_with_image':
          return (
            <CtaHeadingWithImage
              key={i}
              data={slice.primary as PageBodyCta_Heading_With_ImagePrimary}
            />
          )
        case 'text_columns':
          return (
            <TextColumns
              key={i}
              primary={slice.primary as PageBodyText_ColumnsPrimary}
              fields={slice.fields as PageBodyText_ColumnsFields[]}
            />
          )
        case 'story':
          return (
            <Story
              key={i}
              primary={slice.primary as PageBodyStoryPrimary}
              fields={slice.fields as PageBodyStoryFields[]}
            />
          )
        case 'spacer':
          return <Spacer key={i} primary={slice.primary as PageBodySpacerPrimary} />
        case 'claims_with_animation':
          return (
            <ClaimsWithAnimation
              key={i}
              primary={slice.primary as PageBodyClaims_With_AnimationPrimary}
              fields={slice.fields as PageBodyClaims_With_AnimationFields[]}
            />
          )
        case 'heading_with_images':
          return (
            <HeadingWithImages
              key={i}
              primary={slice.primary as PageBodyHeading_With_ImagesPrimary}
              fields={slice.fields as PageBodyHeading_With_ImagesFields[]}
            />
          )
        case 'person_slice':
          return (
            <PersonSlice
              key={i}
              {...((slice.primary as ServiceBodyPerson_SlicePrimary).person as PersonSliceProps)}
            />
          )
        case 'newsletter':
          return <SubscribeNewsletter key={i} />
        case 'custom_subscription':
          return (
            <SubscribeNewsletter
              key={i}
              primary={slice.primary as ArticleBodyCustom_SubscriptionPrimary}
            />
          )
        case 'article_content':
          return (
            <ArticleContent key={i} primary={slice.primary as ArticleBodyArticle_ContentPrimary} />
          )
        case 'heading_with_columns':
          return (
            <HeadingWithColumns
              key={i}
              primary={slice.primary as ServiceBodyHeading_With_ColumnsPrimary}
              fields={slice.fields as ServiceBodyHeading_With_ColumnsFields[]}
            />
          )
        case 'partnership':
          return (
            <Partnership
              key={i}
              primary={slice.primary as PageBodyPartnershipPrimary}
              fields={slice.fields as PageBodyPartnershipFields[]}
            />
          )
        case 'picture_columns':
          return (
            <PictureColumns
              key={i}
              primary={slice.primary as ServiceBodyPicture_ColumnsPrimary}
              fields={slice.fields as ServiceBodyPicture_ColumnsFields[]}
            />
          )
        case 'microsite_landing_page_hero':
          return (
            <MicrositeLandingHero
              key={i}
              data={slice.primary as Microsite_Landing_PageBodyMicrosite_Landing_Page_HeroPrimary}
            />
          )
        case 'microsite_subpage_hero':
          return (
            <MicrositeSubpageHero
              key={i}
              data={slice.primary as Microsite_SubpageBodyMicrosite_Subpage_HeroPrimary}
            />
          )
        case 'tiles':
          return (
            <Tiles
              key={i}
              primary={slice.primary as Microsite_SubpageBodyTilesPrimary}
              fields={slice.fields as TilesField[]}
            />
          )
        case 'videos_list':
          return (
            <VideosList key={i} fields={slice.fields as Microsite_SubpageBodyVideos_ListFields[]} />
          )
        case 'articles_heading':
          return (
            <ArticlesHeading
              key={i}
              primary={slice.primary as Microsite_Landing_PageBodyArticles_HeadingPrimary}
            />
          )
        case 'accordion_with_image':
          return (
            <AccordionWithImage
              key={i}
              primary={slice.primary as ServiceBodyAccordion_With_ImagePrimary}
              fields={slice.fields as ServiceBodyAccordion_With_ImageFields[]}
            />
          )
        case 'accordion_with_heading':
          return (
            <AccordionWithHeading
              key={i}
              primary={slice.primary as ServiceBodyAccordion_With_HeadingPrimary}
              fields={slice.fields as ServiceBodyAccordion_With_HeadingFields[]}
            />
          )
        case 'logos':
          return (
            <Logos
              key={i}
              primary={slice.primary as Case_StudyBodyLogosPrimary}
              fields={slice.fields as Case_StudyBodyLogosFields[]}
            />
          )
        case 'large_heading':
          return <LargeHeading key={i} primary={slice.primary as PageBodyLarge_HeadingPrimary} />
        case 'case_studies_slider':
          return caseStudies && <CaseStudiesSlider key={i} caseStudies={caseStudies} />
        case 'cta_banner_with_image':
          return (
            <CtaBannerWithImage
              key={i}
              primary={slice.primary as PageBodyCta_Banner_With_ImagePrimary}
            />
          )
        default:
          console.log(
            `Panic! Null slice on for position ${i}, please check if you have all slices covered in the graphQL query `
          )
      }
    })
  )
}
