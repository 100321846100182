import { PageBodyIconsFields } from 'types/graphql'
import { SectionMain } from 'components/Shared'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  data: PageBodyIconsFields[]
}

export const Icons: FC<PropsWithChildren<Props>> = ({ data }) => (
  <SectionMain>
    <div className="flex flex-wrap items-center">
      {data.map((icon, i) => (
        <div key={i} className="flex flex-1 p-15">
          <img
            className="m-auto min-h-25  min-w-25 lg:min-h-30 lg:min-w-30"
            src={icon.image?.url}
            alt={icon.image?.alt}
          />
        </div>
      ))}
    </div>
  </SectionMain>
)
