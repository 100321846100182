import { FC, RefObject, useCallback } from 'react'
import ArrowRight from 'assets/icons/arrow_right.svg'
import { useWindowSize } from 'utils/useWindowSize'
import type { PropsWithChildren } from 'react'

const SMALLSHIFT = 316
const MEDIUMSHIFT = 532
const BIGSHIFT = 624
const MEDIUMWIDTH = 767
const SMALLWIDTH = 600

interface ScrollOptions {
  id?: string
  ref?: React.RefObject<unknown>
  x?: number
  y?: number
  smooth?: boolean
}

interface Props {
  scrollToFunction: (props?: ScrollOptions | undefined) => void
  scrollContainerRef: RefObject<HTMLDivElement>
}

export const SliderArrows: FC<PropsWithChildren<Props>> = ({
  scrollToFunction,
  scrollContainerRef,
}) => {
  const { width } = useWindowSize()
  const handleClick = useCallback(
    (number: number) => {
      if (!scrollContainerRef.current) return

      scrollToFunction({
        ref: scrollContainerRef,
        x: scrollContainerRef.current.scrollLeft + number,
        smooth: true,
      })
    },
    [scrollContainerRef, scrollToFunction]
  )

  return (
    <div className="flex -translate-x-5 transform select-none space-x-32 sm:space-x-40 md:space-x-60">
      <div
        className="flex cursor-pointer justify-center p-5 align-middle"
        onClick={() =>
          handleClick(
            width && width > MEDIUMWIDTH
              ? -BIGSHIFT
              : width && width > SMALLWIDTH
              ? -MEDIUMSHIFT
              : -SMALLSHIFT
          )
        }
      >
        <ArrowRight
          className="w-40 rotate-180 transform sm:w-65 md:w-110 lg:hover:animate-arrowBounceLeft"
          style={{ strokeLinecap: 'round' }}
        />
      </div>
      <div
        className="flex cursor-pointer justify-center p-5 align-middle"
        onClick={() =>
          handleClick(
            width && width > MEDIUMWIDTH
              ? BIGSHIFT
              : width && width > SMALLWIDTH
              ? MEDIUMSHIFT
              : SMALLSHIFT
          )
        }
      >
        <ArrowRight
          className="w-40 transform sm:w-65 md:w-110 lg:hover:animate-arrowBounceRight"
          style={{ strokeLinecap: 'round' }}
        />
      </div>
    </div>
  )
}
