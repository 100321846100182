import { SectionMain } from 'components/Shared'
import {
  ServiceBodyAccordion_With_HeadingFields,
  ServiceBodyAccordion_With_HeadingPrimary,
} from 'types/graphql'
import { RichText } from 'prismic-reactjs'
import Image from 'next/image'
import { AccordionItem } from 'components/Shared/AccordionItem'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  primary: ServiceBodyAccordion_With_HeadingPrimary
  fields: ServiceBodyAccordion_With_HeadingFields[]
}

export const AccordionWithHeading: FC<PropsWithChildren<Props>> = ({
  primary: { heading_strong, image },
  fields,
}) => {
  return (
    <SectionMain>
      <div className="flex flex-col items-center lg:justify-between xl:flex-row xl:items-start xl:space-x-20">
        {image && (
          <div className="flex flex-col space-y-32 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-32 lg:space-x-50 xl:max-w-300 xl:flex-col xl:items-start xl:space-x-0 xl:space-y-48 xxl:max-w-340">
            <div className="w-full">
              <div className="h2-larger text-stroke font-heading text-transparent">
                <RichText render={heading_strong} />
              </div>
            </div>
            <div className="w-full max-w-180 self-center xs:max-w-200 sm:max-w-340">
              <Image
                src={image.url}
                alt={image.alt}
                width={340}
                height={340}
                objectFit="contain"
                layout="responsive"
              />
            </div>
          </div>
        )}

        <ul className="w-full max-w-580">
          {fields.map((item, i) => (
            <AccordionItem
              key={i}
              number={i}
              claim={item.claim}
              heading={item.heading}
              isLastItem={fields.length === i + 1}
            />
          ))}
        </ul>
      </div>
    </SectionMain>
  )
}
