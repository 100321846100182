import { SectionMain } from 'components/Shared'
import { Case_StudyBodyQuotePrimary } from 'types/graphql'
import { RichText } from 'prismic-reactjs'
import Fade from 'react-reveal'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import Image from 'next/image'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  data: Case_StudyBodyQuotePrimary
}

export const Quote: FC<PropsWithChildren<Props>> = ({
  data: { quote, author_name, author_role, author_profile },
}) => (
  <SectionMain>
    <Fade>
      <div className="flex flex-col items-center md:flex-row-reverse md:justify-center lg:mx-auto lg:flex-row-reverse lg:justify-center lg:leading-9">
        <ParallaxWrapper deflection={50} breakpoint="md">
          <div className="max-w-350 space-y-20 text-center text-22 sm:max-w-400 sm:text-32 md:ml-100 md:text-left lg:ml-120 lg:max-w-500 xl:max-w-600 xxl:max-w-700">
            <blockquote className="quote relative">
              <RichText render={quote} />
            </blockquote>
          </div>
        </ParallaxWrapper>
        <div className="mt-24 flex items-center sm:mt-32 md:mt-0 lg:my-0 lg:flex-shrink-0">
          <div className="h-35 w-35 overflow-hidden rounded-full sm:h-70 sm:w-70">
            <Image
              src={author_profile?.url}
              alt={author_profile?.alt}
              width={70}
              height={70}
              layout="responsive"
              objectFit="contain"
            />
          </div>
          <div className="ml-16 sm:ml-28">
            <div className="text-12 leading-4 tracking-wider sm:text-14">{author_name}</div>
            <div className="text-12 leading-4 text-tundora sm:text-14">{author_role}</div>
          </div>
        </div>
      </div>
    </Fade>
  </SectionMain>
)
