import { FC, Key } from 'react'
import {
  _Linkable,
  Job_OfferConnectionEdge,
  Meta,
  PageBodyListPrimary,
  Service,
} from 'types/graphql'
import { Label, SectionMain } from 'components/Shared'
import ArrowBullet from 'assets/icons/arrow_bullet.svg'
import StartupIcon from 'assets/icons/logo_startup.svg'
import Fade from 'react-reveal'
import Link from 'next/link'
import cn from 'classnames'
import { linkResolver } from 'utils'
import { useRouter } from 'next/dist/client/router'
import { MicrositeLinks } from 'types/util'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import type { PropsWithChildren } from 'react'

type ServiceLink = {
  service: Pick<Service, 'title' | 'description'> & { _meta: Pick<Meta, 'uid'> } & _Linkable
}

export interface ListProps {
  data: PageBodyListPrimary
  listData: ServiceLink[] | Job_OfferConnectionEdge[] | MicrositeLinks
  listType: 'services' | 'jobs' | 'microsite'
}

const ServicesList = (props: { services: ServiceLink[] }) => (
  <>
    {props.services.map(({ service }, i) => (
      <div className="relative overflow-hidden" key={i}>
        <FlexibleLink
          data={service}
          className={cn(
            'group mb-65 block hover:opacity-100 md:mb-0 md:flex md:flex-row md:items-center md:border-alto md:py-24',
            i === props.services.length - 1 ? 'md:border-0' : 'md:border-b'
          )}
        >
          <div className="md:w-1/2 lg:w-2/3">
            <h2 className="mb-24 font-light transition duration-300 md:mb-0 md:flex md:flex-auto md:text-cloud md:group-hover:text-black100 lg:text-76">
              {service.title}
            </h2>
          </div>
          <div className="-bottom-1/2 right-0 flex h-full transform flex-row transition duration-300 group-hover:opacity-100 md:absolute md:w-1/2 md:max-w-400 md:flex-row-reverse md:items-center md:opacity-0 md:group-hover:-translate-y-1/2 lg:w-1/3">
            <ArrowBullet className="mb-10 block w-80 -translate-x-10 translate-y-5 transform md:mb-0" />
            <p className="flex flex-1 pr-24 text-15 md:text-17">{service.description}</p>
          </div>
        </FlexibleLink>
      </div>
    ))}
  </>
)

const JobOffersList = (props: { jobOffers: Job_OfferConnectionEdge[] }) => (
  <div id="job-offer-list">
    {props.jobOffers.map((jobOffer, i) => {
      const customUrl = jobOffer.node._meta.tags.includes('Students') && '/students'

      return (
        <FlexibleLink
          data={customUrl || jobOffer.node}
          key={i}
          className={cn(
            'group flex flex-row items-center justify-between border-alto py-24 hover:opacity-100 md:mb-0',
            i === props.jobOffers.length - 1 ? 'border-0' : 'border-b'
          )}
        >
          <h2 className="font-light transition duration-300 md:mb-0 md:flex md:flex-auto lg:text-48 lg:text-cloud lg:group-hover:text-black100 ">
            {jobOffer.node.title}
          </h2>
          <div className="flex flex-row transition duration-300 group-hover:opacity-100 md:max-w-400 md:flex-row-reverse md:items-center lg:opacity-0">
            <ArrowBullet className="block translate-y-5" />
          </div>
        </FlexibleLink>
      )
    })}
    <div className="mt-55 flex w-370 flex-row items-center font-heading text-10 tracking-wider xxs:text-12 sm:text-13 sm:tracking-mid-wider">
      <StartupIcon className="mr-2 h-20 w-24 object-contain sm:h-32 sm:w-36" />
      <div>
        FIND OUT MORE ON
        <a
          href="https://www.startupjobs.cz/startup/applifting"
          className="ml-5 inline-block border-b"
          target="_blank"
          rel="noreferrer"
        >
          STARTUP JOBS
        </a>
      </div>
    </div>
  </div>
)

const MicrositeSubpagesList = (props: { subpages: MicrositeLinks }) => {
  const router = useRouter()
  const allLinks = [
    ...props.subpages.map(link => ({ ...link, isHome: false })),
    { link: { ...props.subpages[0].link.microsite }, isHome: true },
  ]
  const finalLinks = allLinks.filter(
    ({ link }) => linkResolver(link) !== router.asPath.split('#')[0]
  )

  return (
    <div className="-my-32">
      {finalLinks.map(({ link, isHome }, i) => {
        const content = (href?: string, key?: Key | null) => (
          <a
            className={cn(
              'md:pb-42 group flex flex-col space-y-24 border-alto py-48 hover:opacity-100 xs:flex-row xs:justify-between sm:items-center md:mb-0 md:pt-50 lg:pt-64 xl:pt-72',
              i === finalLinks.length - 1 ? 'border-0' : 'border-b'
            )}
            href={href}
            key={key}
          >
            <h2 className="font-light transition duration-300 md:mb-0 md:flex md:flex-auto lg:text-48 lg:text-cloud lg:group-hover:text-black100 xl:text-72 ">
              {link.title}
            </h2>
            <div className="flex flex-row transition duration-300 group-hover:opacity-100 md:max-w-400 md:flex-row-reverse md:items-center lg:opacity-0">
              <ArrowBullet className="block -translate-x-14 transform xs:translate-x-0 xs:-translate-y-6 sm:-translate-y-12" />
            </div>
          </a>
        )
        if (isHome) return content('/', i)
        return (
          <Link href={linkResolver(link)} key={i} passHref>
            {content()}
          </Link>
        )
      })}
    </div>
  )
}

export const List: FC<PropsWithChildren<ListProps>> = ({ data, listType, listData }) => {
  const listSwitch = () => {
    switch (listType) {
      case 'services':
        return <ServicesList services={listData as ServiceLink[]} />
      case 'jobs':
        return <JobOffersList jobOffers={listData as Job_OfferConnectionEdge[]} />
      case 'microsite':
        return <MicrositeSubpagesList subpages={listData as MicrositeLinks} />
    }
  }

  return (
    <SectionMain>
      <div className="mt-100 lg:mt-0" />
      <Label className="mb-50 md:mb-32">{data.heading}</Label>
      <Fade>{listSwitch()}</Fade>
    </SectionMain>
  )
}
