import { Button } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import Image from 'next/image'
import type { PropsWithChildren } from 'react'

import { PageBodyCta_Banner_With_ImagePrimary } from 'types/graphql'

import { FC } from 'react'

interface Props {
  primary: PageBodyCta_Banner_With_ImagePrimary
}

export const CtaBannerWithImage: FC<PropsWithChildren<Props>> = ({
  primary: { cta_label, banner_image, banner_text, banner_title },
}) => (
  <section className=" my-25 w-full px-15 md:px-60 lg:px-80">
    <div className="mx-auto flex max-w-1280 grow flex-col justify-between space-y-30 bg-orange px-30 py-50 xs:p-60 sm:p-80 md:flex-row md:p-40">
      <div className="relative flex flex-col justify-center lg:w-1/2">
        <div className="absolute -left-35 bottom-0 sm:-left-50">
          <Image
            src={banner_image.url}
            alt={banner_image.alt}
            width={156}
            height={212}
            layout="intrinsic"
            objectFit="contain"
          />
        </div>

        <h3 className="sm:text-38 lg:text-38 xl:text-46 ginto-nord relative text-32 leading-10 xxs:text-36 xs:mr-75 lg:mr-0 lg:ml-50">
          {banner_title}
        </h3>
      </div>

      <div className="relative flex flex-col justify-center lg:w-1/3">
        <div className="text-18 sm:text-20">
          <RichText render={banner_text} />
        </div>
        <Button
          type="link"
          href="/savelives" // TODO: replace with cta_link
          customClass="flex-row children:ml-0 children:mt-6 sm:children:mt-0 space-x-20 lg:text-16 uppercase mt-25"
          noWrap
          whitespaceWrap
          alignLeftMobile
          center
        >
          {cta_label}
        </Button>
      </div>
    </div>
  </section>
)
