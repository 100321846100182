import axios from 'axios'
import { Button } from 'components/Shared'
import { Checkbox } from 'components/Shared/Checkbox'
import { RichText } from 'prismic-reactjs'
import { useForm } from 'react-hook-form'
import { load } from 'recaptcha-v3'
import { LayoutFormContact_FormPrimary, LayoutFormJob_FormPrimary } from 'types/graphql'
import { InputFormField } from './Shared/InputFormField'
import { TextAreaFormField } from './Shared/TextAreaFormField'
import { emailRegex, prismicHtmlSerializer } from '../../utils'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  form: LayoutFormJob_FormPrimary | LayoutFormContact_FormPrimary
}

const defaultValues = { name: '', email: '', message: '', checkbox: false, serverError: '' }

export const FooterForm: FC<PropsWithChildren<Props>> = props => {
  const { register, handleSubmit, errors, watch, formState, setError, reset } = useForm({
    defaultValues,
  })

  const onSubmit = handleSubmit(async (data: FormData) => {
    try {
      let token: string | undefined = undefined
      const formData = new FormData()

      if (process.env.NEXT_PUBLIC_RECAPTCHA_KEY) {
        const recaptcha = await load(process.env.NEXT_PUBLIC_RECAPTCHA_KEY)
        token = await recaptcha.execute('contact')
        formData.append('captcha', token)
      }
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value)
      })

      await axios.post('/api/contact', formData)
    } catch (ex) {
      setError('serverError', {
        type: 'server',
        message: 'Something went wrong, please try again later',
      })
      console.log(ex)
    }
  })

  return (
    <div className="mb-36 w-full text-white xl:w-half xl:max-w-550">
      <form onSubmit={onSubmit} className="-mt-150 flex flex-col pt-150" id="footer-form">
        <div className="heading-strong-white text-stroke-white h2-large mb-32 font-heading text-transparent sm:mb-60">
          <RichText render={props.form.heading_strong} />
        </div>
        <div className="mb-36 space-y-16 md:space-y-22 lg:space-y-44">
          <>
            <InputFormField
              ref={register({ required: 'This field is required' })}
              name="name"
              label={(props.form as LayoutFormContact_FormPrimary).name_placeholder}
              errors={errors.name?.message}
              isEmpty={!!watch('name')}
            />
            <InputFormField
              ref={register({
                required: 'This field is required',
                pattern: {
                  value: emailRegex,
                  message: 'Entered value does not match email format',
                },
              })}
              name="email"
              label={props.form.email_placeholder}
              errors={errors.email?.message}
              isEmpty={!!watch('email')}
            />
            <TextAreaFormField
              ref={register({ required: 'This field is required' })}
              name="message"
              label={props.form.message_placeholder}
              isEmpty={!!watch('message')}
              errors={errors.message?.message}
            />
          </>
        </div>
        <div className="mb-30">
          <div className="relative flex">
            <label className="flex text-12 leading-5 text-gray">
              <Checkbox ref={register({ required: 'Please check the box' })} name="checkbox" />
              <RichText render={props.form.checkmarks} htmlSerializer={prismicHtmlSerializer} />
            </label>
          </div>
          {errors.checkbox && <p className="mt-8 text-16 text-red">{errors.checkbox.message}</p>}
        </div>

        {formState.isSubmitSuccessful ? (
          <div className="flex w-full flex-row items-center justify-between bg-black500 px-32 py-16">
            <div className="text-17">Message successfully sent</div>
            <div
              className="ginto-nord cursor-pointer text-11 transition-colors duration-200 hover:text-alto"
              onClick={() => reset(defaultValues)}
            >
              Send new message
            </div>
          </div>
        ) : (
          <Button type="button" submit={true} disabled={formState.isSubmitting}>
            {formState.isSubmitting ? 'Sending...' : 'Send message'}
          </Button>
        )}
      </form>
      {errors.serverError && <p className="text-16 text-red">{errors.serverError.message}</p>}
    </div>
  )
}
