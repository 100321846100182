import { AlignEnum, Tile } from 'components/CaseStudy/Tile'
import { SectionMain } from 'components/Shared'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { FC } from 'react'
import { Meta, Microsite_SubpageBodyTilesPrimary } from 'types/graphql'
import { PrismicImage } from 'types/util'
import { ROUTES } from 'utils'
import cn from 'classnames'
import type { PropsWithChildren } from 'react'

export type TilesField = {
  doc: {
    _meta: Pick<Meta, 'uid'>
    microsite: {
      _meta: Pick<Meta, 'uid'>
    }
    title: RichTextBlock[]
    article_thumbnail: PrismicImage
  }
}

interface Props {
  primary: Microsite_SubpageBodyTilesPrimary
  fields: TilesField[]
}

export const Tiles: FC<PropsWithChildren<Props>> = ({ primary: { link_label }, fields }) => {
  return (
    <SectionMain>
      <div
        className={cn(
          'mt-50 grid gap-y-50 xs:gap-y-70 sm:mt-100 lg:grid-cols-2 lg:gap-y-200 lg:gap-x-20',
          { 'lg:mb-200 xl:mb-250': fields.length % 2 === 0 }
        )}
      >
        {fields.map(
          (
            {
              doc: {
                title,
                _meta: { uid },
                microsite,
                article_thumbnail,
              },
            },
            index: number
          ) => (
            <Tile
              title={RichText.asText(title)}
              image={article_thumbnail}
              url={ROUTES.microsite_article(uid, microsite._meta.uid)}
              key={index}
              align={index % 2 == 0 ? AlignEnum.right : AlignEnum.left}
              startLeft
              linkLabel={link_label}
            />
          )
        )}
      </div>
    </SectionMain>
  )
}
