import Facebook from 'assets/images/Facebook.svg'
import Instagram from 'assets/images/instagram.svg'
import Linkedin from 'assets/images/linkedin.svg'
import { Button } from 'components/Shared'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import Image from 'next/image'
import { PrismicImage } from 'types/util'
import { _ExternalLink } from 'types/graphql'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

export interface PersonSliceProps {
  name: RichTextBlock[]
  position: string
  about: string
  profile_img: PrismicImage
  cta_link: _ExternalLink
  facebook_link: _ExternalLink
  instagram_link: _ExternalLink
  linkedin_link: _ExternalLink
}

export const PersonSlice: FC<PropsWithChildren<PersonSliceProps>> = props => {
  return (
    <section className="w-full max-w-1280 lg:px-80">
      <div className="relative mt-150 flex w-full flex-col bg-black100 md:mt-0 md:flex-row">
        <div className="absolute -top-115 left-32 sm:left-100 md:relative md:top-0 md:left-0">
          <div className="h-450 w-260 sm:w-300 md:h-507 xl:w-340">
            {props.profile_img?.url && (
              <Image src={props.profile_img.url} layout="fill" objectFit="cover" />
            )}
          </div>
        </div>
        <div className="flex w-full flex-col pt-380 pb-35 md:justify-around md:pt-70 md:pb-85 lg:flex-row">
          <div className="relative mb-100 flex flex-col px-32 sm:px-100 md:mb-0 md:px-48 lg:px-20">
            <p className="pb-5 text-14 uppercase text-darkgray">CEO</p>
            <p className="pb-55 text-48 leading-64 text-white md:pb-24 md:text-32 lg:pb-0 lg:text-48">
              <RichText render={props.name} />
            </p>
            {props.cta_link && (
              <Button
                type="link"
                customClass="text-white uppercase absolute md:relative lg:absolute -bottom-60 md:bottom-0 lg:-bottom-32 text-13 text-left items-end"
                href={props.cta_link}
                inlineArrow
                noWrap
              >
                Schedule a free
                <br />
                30 min call
              </Button>
            )}
          </div>
          <div className="px-32 sm:px-100 md:max-w-full md:px-48 lg:max-w-315 lg:px-10">
            <p className="pb-10 text-14 uppercase text-darkgray md:pt-44 lg:pt-0">About</p>
            <p className="text-17 leading-30 text-white">{props.about}</p>
            <div className="mt-26 flex items-end">
              {props.facebook_link && (
                <a
                  href={props.facebook_link.url}
                  className="mr-26 block transform rounded-full duration-100 hover:-translate-y-3"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Facebook />
                </a>
              )}
              {props.instagram_link && (
                <a
                  href={props.instagram_link.url}
                  className="mr-26 block transform rounded-full duration-100 hover:-translate-y-3"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Instagram />
                </a>
              )}
              {props.linkedin_link && (
                <a
                  href={props.linkedin_link.url}
                  className="mr-26 block transform rounded-full duration-100 hover:-translate-y-3"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Linkedin />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
