import { Button, Label } from 'components/Shared'
import { Maybe } from 'types/graphql'
import cn from 'classnames'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import { PrismicImage } from 'types/util'
import { getHeightInPercentageOfWidth } from 'utils'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

export enum AlignEnum {
  left = 'left',
  right = 'right',
}

interface Props {
  title: string
  description?: string
  image: PrismicImage
  url: string
  align: AlignEnum
  startLeft?: boolean
  linkLabel?: Maybe<string> | undefined
}

export const Tile: FC<PropsWithChildren<Props>> = ({
  title,
  description,
  image,
  url,
  align,
  startLeft = false,
  linkLabel,
}) => {
  const adjustAlign = () => {
    if (!startLeft) return align
    return align === AlignEnum.left ? AlignEnum.right : AlignEnum.left
  }

  const wrapperClass = cn(
    'w-full max-w-550 lg:pl-0 lg:pr-50 lg:max-w-600',
    adjustAlign() === AlignEnum.right
      ? cn('lg:col-start-2', { 'lg:transform lg:translate-y-1/3': startLeft })
      : cn('justify-self-end lg:justify-self-auto lg:col-start-1', {
          'lg:transform lg:-translate-y-4/5': !startLeft,
        }),
    { group: linkLabel }
  )

  return (
    <FlexibleLink data={url} className={wrapperClass}>
      <div
        className={cn('mb-20 flex', {
          'flex-row-reverse justify-self-start lg:flex-row': adjustAlign() === AlignEnum.left,
          'mr-32 sm:mr-0': adjustAlign() === AlignEnum.left && !image?.alt,
          'ml-32 sm:ml-0': adjustAlign() === AlignEnum.right && !image?.alt,
        })}
      >
        <div
          className="relative h-0 w-full overflow-y-hidden"
          style={{
            paddingBottom: getHeightInPercentageOfWidth(
              image?.dimensions?.width,
              image?.dimensions?.height,
              0.8
            ),
          }}
        >
          <div className="absolute flex h-full w-full items-center">
            <ParallaxWrapper deflection={adjustAlign() === AlignEnum.right ? 25 : -25}>
              <img src={image?.url} alt={image?.alt || ''} className="w-full" />
            </ParallaxWrapper>
          </div>
        </div>
        {image?.alt && (
          <div
            style={{ writingMode: 'vertical-rl' }}
            className={cn(
              'origin-bottom origin-center -translate-y-full -rotate-180 transform whitespace-nowrap text-14 text-darkgray',
              {
                'mr-12 lg:mr-0 lg:ml-12': adjustAlign() === AlignEnum.left,
                'ml-12': adjustAlign() === AlignEnum.right,
              }
            )}
          >
            {image?.alt}
          </div>
        )}
      </div>
      <div
        className={cn({
          'ml-33 lg:ml-0': image?.alt && adjustAlign() === AlignEnum.left,
          'ml-32 sm:ml-0': adjustAlign() === AlignEnum.right && !image?.alt,
        })}
      >
        {description && <Label className="mb-4">{title}</Label>}
        <p className="max-w-3/4 text-30 md:text-32">{description || title}</p>
        {linkLabel && (
          <Button
            type="link"
            customClass="uppercase w-min-content h-fit-content lg:mr-auto lg:items-center mt-40"
            fontSizeClasses="text-12 xs:text-13"
          >
            {linkLabel}
          </Button>
        )}
      </div>
    </FlexibleLink>
  )
}
