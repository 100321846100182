import { Case_StudyBodyNumbersPrimary, PageBodyNumbersFields } from 'types/graphql'
import { SectionMain } from 'components/Shared'
import Fade from 'react-reveal'
import { FC } from 'react'
import cn from 'classnames'
import type { PropsWithChildren } from 'react'

interface Props {
  primary?: Case_StudyBodyNumbersPrimary
  fields: PageBodyNumbersFields[]
  isLast: boolean
}

export const Numbers: FC<PropsWithChildren<Props>> = ({ primary, fields, isLast }) => (
  <SectionMain>
    <Fade bottom collapse>
      <ul
        className={cn('flex flex-wrap justify-center gap-50', {
          'lg:justify-between': primary?.space_between,
          'border-b border-white100 pb-50 lg:pb-80': isLast,
        })}
      >
        {fields?.map((number, i) => (
          <div
            key={i}
            className={cn({
              'lg:flex-1': !primary?.space_between,
            })}
          >
            <div className="text-stroke whitespace-nowrap font-heading text-42 leading-none text-white md:text-82">
              <strong>{number.number_label}</strong>
            </div>
            <div className="mb-20 max-w-130 text-14 md:max-w-none md:text-16">{number.label}</div>
          </div>
        ))}
      </ul>
    </Fade>
  </SectionMain>
)
