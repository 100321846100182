import { Microsite_Landing_PageBodyMicrosite_Landing_Page_HeroPrimary } from 'types/graphql'
import { Button, SectionMain } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  data: Microsite_Landing_PageBodyMicrosite_Landing_Page_HeroPrimary
}

export const MicrositeLandingHero: FC<PropsWithChildren<Props>> = ({
  data: { title, image, claim, cta_label, cta_link },
}) => {
  const hasDescription = !!RichText.asText(claim)?.length

  return (
    <SectionMain className="lg:pb-50">
      <ParallaxWrapper deflection={30}>
        <div className="space-y-32 pt-75 lg:space-y-48 xl:pt-150">
          <h1 className="heading-strong text-stroke ginto-nord relative font-heading text-40 text-transparent sm:text-54 md:text-64 lg:text-88 xl:text-110 xxxl:text-180">
            <div className="absolute top-half left-half -z-1 w-full">
              <ParallaxWrapper deflection={-30}>
                <img
                  className="h-full w-full -translate-x-half transform object-contain xs:max-w-450 lg:max-w-550 xl:max-w-750 xxl:max-w-800"
                  src={image?.url}
                  alt={image?.alt}
                />
              </ParallaxWrapper>
            </div>
            <RichText render={title} />
          </h1>
          <div className="flex max-w-320 flex-col md:ml-auto md:flex-col-reverse lg:max-w-640">
            <Button
              href={cta_link}
              type="link"
              customClass="uppercase items-center w-min-content h-fit-content xxs:whitespace-nowrap"
              fontSizeClasses="text-12 lg:text-16"
              noWrap
              whitespaceWrap
            >
              {cta_label}
            </Button>
            {hasDescription && (
              <p className="mt-82 max-w-240 self-end text-22 md:mt-0 md:mb-64 md:max-w-full md:self-start lg:mb-64 lg:text-36 xl:text-42">
                <RichText render={claim} />
              </p>
            )}
          </div>
        </div>
      </ParallaxWrapper>
    </SectionMain>
  )
}
