import { RichText } from 'prismic-reactjs'
import { FC, useRef } from 'react'
import {
  PageBodyHeading_With_ImagesFields,
  PageBodyHeading_With_ImagesPrimary,
} from 'types/graphql'
import Facebook from 'assets/icons/facebook_simple.svg'
import Twitter from 'assets/icons/twitter.svg'
import Linkedin from 'assets/icons/linkedin.svg'
import Medium from 'assets/icons/medium.svg'
import ArrowRight from 'assets/icons/arrow_right_long.svg'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import ScrollContainer from 'react-indiana-drag-scroll'
import { ScrollTo } from 'react-scroll-to/dist'
import type { PropsWithChildren } from 'react'

interface MainProps {
  primary: PageBodyHeading_With_ImagesPrimary
  fields: PageBodyHeading_With_ImagesFields[]
}

export const HeadingWithImages: FC<PropsWithChildren<MainProps>> = ({
  primary: { heading_strong, follow_text },
  fields,
}) => {
  const imageRefs = useRef<HTMLImageElement[]>([])
  const scrollContainerRef = useRef<any>(null)

  return (
    <section className="my-100 w-full">
      <ScrollTo>
        {({ scroll }) => (
          <div className="mx-auto flex max-w-1660 flex-col lg:flex-row">
            <div className="mx-15 text-black100 lg:mx-0 lg:flex lg:w-half lg:flex-col lg:items-end lg:justify-center">
              <ParallaxWrapper deflection={-60} breakpoint="lg">
                <div className="flex flex-col items-center text-center lg:ml-80 lg:max-w-640 lg:items-start lg:text-left">
                  <div className="text-stroke mb-30 max-w-600 font-heading text-white children:text-40 sm:children:text-50 lg:max-w-full lg:pr-36 xl:pr-50 xl:children:text-64">
                    <RichText render={heading_strong} />
                  </div>
                  <div className="mb-45 flex max-w-600 flex-col items-center text-tundora lg:max-w-full lg:flex-row lg:text-black100">
                    <p className="mb-16 text-17 lg:mb-0 lg:mr-30">{follow_text}</p>
                    <div className="flex space-x-16">
                      <a
                        className="flex items-center justify-center"
                        href="https://www.facebook.com/appliftingcz"
                      >
                        <Facebook className="w-13 lg:w-8" />
                      </a>
                      <a
                        className="flex items-center justify-center"
                        href="https://twitter.com/applifting_cz"
                      >
                        <Twitter className="w-20 lg:w-13" />
                      </a>
                      <a
                        className="flex items-start justify-center"
                        href="https://www.linkedin.com/company/applifting-cz"
                      >
                        <Linkedin className="w-20 lg:w-13" />
                      </a>
                      <a
                        className="flex items-center justify-center"
                        href="https://medium.com/applifting-cz"
                      >
                        <Medium className="w-20 lg:w-13" />
                      </a>
                    </div>
                  </div>
                  <div className="hidden -translate-x-5 transform select-none space-x-60 lg:flex">
                    <div
                      className="cursor-pointer p-5"
                      onClick={() =>
                        scroll({
                          ref: scrollContainerRef.current,
                          x: scrollContainerRef.current.scrollLeft - 500,
                          smooth: true,
                        })
                      }
                    >
                      <ArrowRight className="w-110 rotate-180 transform lg:hover:animate-arrowBounceLeft" />
                    </div>
                    <div
                      className="cursor-pointer p-5"
                      onClick={() =>
                        scroll({
                          ref: scrollContainerRef.current,
                          x: scrollContainerRef.current.scrollLeft + 500,
                          smooth: true,
                        })
                      }
                    >
                      <ArrowRight className="w-110 transform lg:hover:animate-arrowBounceRight" />
                    </div>
                  </div>
                </div>
              </ParallaxWrapper>
            </div>

            <ScrollContainer
              innerRef={scrollContainerRef}
              className="overflow-x-auto overscroll-x-contain lg:w-half lg:overflow-x-scroll"
            >
              <div className="flex flex-shrink-0 items-center space-x-45">
                {fields.map((field, i) => (
                  <img
                    key={i}
                    src={field.image.url}
                    alt={field.image.alt}
                    className="h-215 object-contain first:ml-15 xs:h-300 sm:h-350 md:h-450 lg:h-500 lg:first:ml-0"
                    ref={(el: HTMLImageElement) => (imageRefs.current[i] = el)}
                  />
                ))}
              </div>
            </ScrollContainer>
          </div>
        )}
      </ScrollTo>
    </section>
  )
}
