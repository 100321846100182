import { Header } from './Header/Header'
import { Footer } from './Footer/Footer'
import {
  Guru,
  Layout as LayoutType,
  LayoutFooter_Main,
  LayoutFooter_Right,
  TeamRoles,
} from 'types/graphql'
import Dribble from 'assets/icons/dribble.svg'
import Facebook from 'assets/icons/facebook.svg'
import Instagram from 'assets/icons/instagram.svg'
import Linkedin from 'assets/icons/linkedin.svg'
import Twitter from 'assets/icons/twitter.svg'
import Podcast from 'assets/icons/microphone.svg'
import { LayoutGuru } from './Footer/ContactList'
import { LayoutBase } from './LayoutBase'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import { MainSiteLinks } from 'types/util'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface LayoutProps {
  preview?: boolean
  lang?: string
  title: string
  description: string
  hrContact?: string
  footerType?: string
  data: LayoutType
  links: MainSiteLinks
  isContactPage?: boolean
  isJobOffer?: boolean
  team?: TeamRoles[]
  recruiteeTag?: string
  meta_image?: string
  prioritizedContact?: Pick<Guru, 'name'> | null | undefined
}

const iconComponents: { [k: string]: JSX.Element } = {
  dribble: <Dribble className="h-18 w-18" />,
  facebook: <Facebook className="h-18 w-18" />,
  instagram: <Instagram className="h-18 w-18" />,
  linkedin: <Linkedin className="h-18 w-18" />,
  twitter: <Twitter className="h-18 w-18" />,
  podcast: <Podcast className="h-18 w-18" />,
}

export const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  preview,
  lang,
  title,
  description,
  hrContact,
  footerType,
  data: { nav, socials, form, footer_main, footer_right, footer_right_text, footer_nav, gurus },
  children,
  isContactPage,
  isJobOffer,
  team,
  recruiteeTag,
  meta_image,
  prioritizedContact,
}) => {
  return (
    <LayoutBase preview={preview} title={title} description={description} metaImage={meta_image}>
      <Header lang={lang} links={nav} />
      <div className="flex-1">
        <div className="fixed left-25 bottom-25 z-10 hidden transform space-y-30 lg:block">
          {socials?.map((social, i) => (
            <FlexibleLink
              key={i}
              data={social.social_link}
              className="block transform rounded-full transition-transform duration-100 hover:-translate-y-3"
            >
              {social?.social_type && iconComponents[social?.social_type]}
            </FlexibleLink>
          ))}
        </div>
        <main className="flex flex-1 flex-col items-center overflow-hidden">{children}</main>
      </div>
      <Footer
        footerMain={footer_main as LayoutFooter_Main[]}
        footerRight={footer_right as LayoutFooter_Right[]}
        footerRightText={footer_right_text}
        footerNav={footer_nav}
        footerType={footerType}
        gurus={gurus as LayoutGuru[] | null | undefined}
        form={form}
        isContactPage={isContactPage}
        isJobOffer={isJobOffer}
        jobTitle={title}
        team={team}
        hrContact={hrContact}
        recruiteeTag={recruiteeTag}
        prioritizedContact={prioritizedContact}
      />
    </LayoutBase>
  )
}
