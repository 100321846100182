import { FC, useState } from 'react'
import { PageBodyTeam_BigPrimary, TeamRoles, TeamRolesRoleFields } from 'types/graphql'
import { SectionMain } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import type { PropsWithChildren } from 'react'

interface Props {
  data: PageBodyTeam_BigPrimary
  team: TeamRoles[]
}

export const TeamBig: FC<PropsWithChildren<Props>> = ({
  data: { title, all_members_label, more_members_label },
  team,
}) => {
  const [filter, setFilter] = useState<{
    members: (TeamRolesRoleFields | undefined)[]
    role: string
    limit: number
  }>({
    members: team.map(a => a.fields?.map(b => b)[0]),
    role: '',
    limit: 8,
  })

  const roles = team?.map(role => role.primary?.role_name)

  const handleFilterRole = (role: string) => {
    if (filter.role !== role) {
      setFilter(state => ({
        ...state,
        role,
        members: team
          .filter(a => a.primary?.role_name?.includes(role))
          .map(b => b.fields?.map(c => c)[0]),
      }))
    }
  }

  const handleLimitIncrease = () => setFilter(state => ({ ...state, limit: state.limit + 8 }))

  const activeClass = (isActive: boolean) =>
    (isActive ? 'bg-turquoiseBlue text-white rounded-full' : 'text-gray tracking-wide') +
    ' px-25 py-12 focus:outline-none uppercase text-14 whitespace-nowrap'

  return (
    <SectionMain>
      <h2 className="heading-strong text-stroke mb-15 font-heading text-24 text-white lg:mb-40 lg:text-64">
        <RichText render={title} />
      </h2>
      <div className="relative">
        <ul className="flex items-center overflow-x-auto">
          <li>
            <button onClick={() => handleFilterRole('')} className={activeClass(!filter.role)}>
              {all_members_label}
            </button>
          </li>
          {roles?.map(role => (
            <li key={role as string}>
              <button
                onClick={() => handleFilterRole(role as string)}
                className={activeClass(filter.role === role)}
              >
                {role}
              </button>
            </li>
          ))}
          <div className="absolute right-0 h-full w-50 bg-gradient-to-l from-white"></div>
        </ul>
      </div>
      <div></div>
      <div className="relative mt-40 grid grid-cols-2 gap-y-40 gap-x-15 pb-30 md:grid-cols-4 md:gap-x-30 lg:pb-80">
        {filter.members
          .slice(0, filter.limit)
          .map((member: TeamRolesRoleFields | undefined, i: number) => (
            <div key={i}>
              <div className="h-250 lg:h-400">
                <img
                  className="h-full w-full object-cover"
                  src={member?.member_profile?.url || '/images/profile-placeholder.png'}
                  alt={member?.member_profile?.alt || 'Profile'}
                />
              </div>
              <div className="mt-10 text-12 uppercase tracking-wide lg:text-14">
                {member?.member_name}
              </div>
              <div className="text-12 text-gray lg:text-14">{member?.member_role}</div>
            </div>
          ))}
        {filter.limit < filter.members.length && (
          <div className="absolute bottom-0 left-0 flex h-100 w-full bg-gradient-to-t from-white">
            <button
              onClick={handleLimitIncrease}
              className="mx-auto mt-auto font-heading text-14 uppercase tracking-widest focus:outline-none"
            >
              {more_members_label}
              <span className="ml-20 rounded-full border border-cloud px-16 py-10 font-body text-16 text-turquoiseBlue">
                {filter.members.length - filter.limit}
              </span>
            </button>
          </div>
        )}
      </div>
    </SectionMain>
  )
}
