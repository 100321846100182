import { PageBodySpacerPrimary } from 'types/graphql'
import cn from 'classnames'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  primary: PageBodySpacerPrimary
}

export const Spacer: FC<PropsWithChildren<Props>> = ({
  primary: { margin_type, height, height_sm, height_lg },
}) => {
  const marginType = margin_type === 'crop top section' ? 'mt' : 'mb'
  const getMarginClass = (height: string) =>
    height.startsWith('-') ? `-${marginType + height}` : `${marginType}-${height}`

  return (
    <div
      className={cn(
        'w-full',
        height && getMarginClass(height),
        height_sm && `sm:${getMarginClass(height_sm)}`,
        height_lg && `lg:${getMarginClass(height_lg)}`
      )}
    ></div>
  )
}
