import { FC } from 'react'
import { Case_StudyBodyLogosFields, Case_StudyBodyLogosPrimary } from 'types/graphql'
import { Label, SectionMain } from 'components/Shared'
import { PrismicImage } from 'types/util'
import Image from 'next/image'
import Fade from 'react-reveal'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import type { PropsWithChildren } from 'react'

interface Props {
  primary: Case_StudyBodyLogosPrimary
  fields: Case_StudyBodyLogosFields[]
}

export const Logos: FC<PropsWithChildren<Props>> = ({ primary: { label }, fields }) => (
  <SectionMain className="py-50">
    <Fade>
      <div className="flex flex-col gap-48 lg:flex-row lg:justify-between">
        <Label className="max-w-225 flex-shrink-0 self-center text-center tracking-extra-widest lg:text-left">
          <ParallaxWrapper deflection={-25} className="h-fit-content">
            {label}
          </ParallaxWrapper>
        </Label>
        <div className="flex flex-wrap justify-center gap-12">
          {fields.map(({ logo }, i) => {
            const { url, alt, dimensions } = (logo as PrismicImage) || {}
            if (!url || !dimensions) return

            const { width, height } = dimensions
            const ratio = width / height

            return <Image key={i} src={url} alt={alt || ''} width={80 * ratio} height={80} />
          })}
        </div>
      </div>
    </Fade>
  </SectionMain>
)
