import { PageBodyImagePrimary } from 'types/graphql'
import { SectionMain } from 'components/Shared'
import Fade from 'react-reveal'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import cn from 'classnames'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  data: PageBodyImagePrimary
}

export const Image: FC<PropsWithChildren<Props>> = ({ data: { image } }) => {
  const { width, height } = image.dimensions
  const isImageLandscape = width >= height

  return (
    <SectionMain>
      <Fade bottom>
        <ParallaxWrapper deflection={-20}>
          <div className={cn(isImageLandscape ? 'w-full' : 'mx-auto w-half')}>
            <img className="h-full w-full object-cover" src={image?.url} alt={image?.alt} />
          </div>
        </ParallaxWrapper>
      </Fade>
    </SectionMain>
  )
}
