import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import { RichText } from 'prismic-reactjs'
import { PageBodyCta_Heading_With_ImagePrimary } from 'types/graphql'
import { prismicHtmlSerializer } from 'utils'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  data: PageBodyCta_Heading_With_ImagePrimary
}

export const CtaHeadingWithImage: FC<PropsWithChildren<Props>> = ({
  data: { heading_link, image },
}) => (
  <section className="my-50 flex w-full lg:my-100 ">
    <div className="mx-auto flex max-w-1660 flex-col lg:w-full">
      <div className="flex flex-col space-y-50 xs:space-y-80 sm:space-y-100 lg:flex-row lg:space-y-0">
        <div className="mr-15 lg:mr-0 lg:w-half">
          <img src={image.url} alt={image.alt} className="w-full object-contain" />
        </div>
        <div className="mx-15 flex flex-col items-center justify-center sm:mx-0 lg:w-half">
          <div className="max-w-515 lg:ml-50 lg:pr-32 xl:mr-50 xl:pr-0">
            <ParallaxWrapper deflection={-30} breakpoint="lg">
              <div className="h2-large heading-link text-stroke font-heading text-40 text-transparent xs:text-48 sm:text-54 xl:text-64">
                <RichText render={heading_link} htmlSerializer={prismicHtmlSerializer} />
              </div>
            </ParallaxWrapper>
          </div>
        </div>
      </div>
    </div>
  </section>
)
