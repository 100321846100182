import { SectionMain } from 'components/Shared'
import {
  ServiceBodyHeading_With_ColumnsFields,
  ServiceBodyHeading_With_ColumnsPrimary,
} from 'types/graphql'
import { RichText } from 'prismic-reactjs'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import Image from 'next/image'
import { TextColumn } from 'components/Shared/TextColumn'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  primary: ServiceBodyHeading_With_ColumnsPrimary
  fields: ServiceBodyHeading_With_ColumnsFields[]
}

export const HeadingWithColumns: FC<PropsWithChildren<Props>> = ({
  primary: { heading_strong, image },
  fields,
}) => {
  return (
    <SectionMain>
      <div className="flex flex-col items-center lg:justify-between xl:flex-row xl:items-start xl:space-x-20">
        <div className="flex flex-col space-y-32 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-32 lg:space-x-50 xl:max-w-300 xl:flex-col xl:items-start xl:space-x-0 xl:space-y-48 xxl:max-w-340">
          <div className="w-full">
            <div className="h2-larger text-stroke font-heading text-transparent">
              <RichText render={heading_strong} />
            </div>
          </div>
          {image?.url && (
            <div className="w-full max-w-180 self-center xs:max-w-200 sm:max-w-340">
              <Image
                src={image.url}
                alt={image.alt}
                width={340}
                height={340}
                objectFit="contain"
                layout="responsive"
              />
            </div>
          )}
        </div>

        <div className="mb-60 flex max-w-800 flex-wrap justify-center xl:relative xl:left-32 xl:-mt-60 xxl:left-0">
          {fields.map((column, i) => (
            <ParallaxWrapper
              className="max-h-full min-w-0 max-w-400"
              deflection={(i + 1) * 18}
              key={i}
            >
              <TextColumn heading={column.heading} claim={column.claim} i={i} />
            </ParallaxWrapper>
          ))}
        </div>
      </div>
    </SectionMain>
  )
}
