import { Case_StudyBodyClaims_With_ImagePrimary } from 'types/graphql'
import { RichText } from 'prismic-reactjs'
import { Label, SectionMain } from 'components/Shared'
import Fade from 'react-reveal'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import Image from 'next/image'
import cn from 'classnames'
import { formatDate } from 'utils'
import { isLinkableUrl } from 'types/util'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  data: Case_StudyBodyClaims_With_ImagePrimary
}

export const ClaimsWithImage: FC<PropsWithChildren<Props>> = ({
  data: {
    label,
    heading,
    claim,
    image,
    image_position,
    date_published,
    video,
    video_mp4,
    vertically_center_text,
    big_claim_text,
  },
}) => {
  const isLeft = image_position === 'left'

  const wrapperStyle =
    (isLeft ? 'lg:flex-row-reverse' : 'lg:flex-row') +
    ' flex flex-col flex-shrink-0 lg:justify-between' +
    (vertically_center_text && ' items-center')
  const imageStyle =
    (isLeft
      ? 'transform mx-auto -translate-x-16/100 xl:-translate-x-200'
      : ' mx-auto transform translate-x-16/100 xl:translate-x-200') +
    ' max-w-9/10 lg:max-w-700 w-full'

  const divStyle =
    (isLeft ? 'mx-auto lg:mr-auto' : ' ') + ' w-full lg:max-w-400 flex-1 md:min-w-350 mb-48'

  const videoStyle =
    (isLeft ? 'transform mx-auto lg:mr-80' : ' mx-auto transforms lg:ml-80') +
    ' lg:max-w-700 lg:mt-70'

  return (
    <SectionMain className="overflow-hidden">
      <Fade>
        <div className="flex flex-col">
          <div className={wrapperStyle}>
            <div className={divStyle}>
              <Label className="w-screen pr-32 lg:w-auto lg:pr-0" newLineAferColon={true}>
                {label}
              </Label>
              <h2 className="my-40 font-light">{heading}</h2>
              <div className={cn(big_claim_text ? 'text-22 sm:text-32' : 'text-17', ' space-y-20')}>
                <RichText render={claim} />
              </div>
              {date_published && (
                <div className="mt-40 text-14 sm:text-17">
                  {formatDate(date_published, { separator: '. ' })}
                </div>
              )}
            </div>
            <div className={video ? videoStyle : imageStyle}>
              {!video ? (
                <ParallaxWrapper breakpoint="lg" deflection={isLeft ? 40 : -40} factor={0.3}>
                  <Image
                    src={image?.url}
                    alt={image?.alt}
                    width={700}
                    height={700}
                    layout="responsive"
                    objectFit="contain"
                    objectPosition="top"
                  />
                </ParallaxWrapper>
              ) : (
                <ParallaxWrapper breakpoint="lg" deflection={isLeft ? 40 : -40}>
                  <video autoPlay playsInline loop muted>
                    {isLinkableUrl(video) && <source src={video.url} type="video/webm" />}
                    {video_mp4 && isLinkableUrl(video_mp4) && (
                      <source src={video_mp4.url} type="video/mp4" />
                    )}
                  </video>
                </ParallaxWrapper>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </SectionMain>
  )
}
