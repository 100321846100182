import { FC, Fragment, MutableRefObject, useEffect, useRef, useState } from 'react'
import { Guru, LayoutFormContact_FormPrimary, TeamRoles, TeamRolesRole } from 'types/graphql'
import cn from 'classnames'
import AnimateHeight from 'react-animate-height'
import { ScrollTo } from 'react-scroll-to'
import ArrowRight from 'assets/icons/arrow_small_right.svg'
import { Label } from 'components/Shared'
import Image from 'next/image'
import type { PropsWithChildren } from 'react'

export interface LayoutGuru {
  guru: Guru
}

interface ContactListProps {
  label: LayoutFormContact_FormPrimary['contact_label']
  contacts: LayoutGuru[] | null | undefined
  isContactPage?: boolean
  isJobOffer?: boolean
  team?: TeamRoles[]
  hrContact?: string
}

interface ContactItemProps {
  contact: Guru
  active: number
  index: number
  handleClick: (index: number) => void
}

const ContactItem: FC<PropsWithChildren<ContactItemProps>> = ({
  contact,
  active,
  index,
  handleClick,
}) => {
  const [height, setHeight] = useState<'auto' | number>('auto')
  const [initialHeight, setInitialHeight] = useState<'auto' | number>('auto')

  const details = useRef() as MutableRefObject<HTMLUListElement>

  useEffect(() => {
    setInitialHeight(details.current.offsetHeight)
  }, [details.current && details.current])

  useEffect(() => {
    if (index === active) {
      setHeight(initialHeight)
    } else {
      setHeight(0)
    }
  }, [active])

  return (
    <li
      className="cursor-pointer border-b border-mineShaft py-9"
      onClick={() => handleClick(index)}
    >
      <div className="flex items-center tracking-wide">
        <span className="mr-10 text-24">{index === active ? '–' : '+'}</span>
        {contact.role_label}
      </div>
      <AnimateHeight
        height={height}
        className={cn(active === index && 'my-8')}
        style={{ transition: 'margin 200ms ease' }}
        duration={200}
      >
        <ul ref={details} className="text-cloud">
          <li className="mb-4">{contact.name}</li>
          <li>{contact.email}</li>
          <li>{contact.phone}</li>
          {contact.phone_uk && <li>{contact.phone_uk}</li>}
        </ul>
      </AnimateHeight>
    </li>
  )
}

const JobOfferContactList: FC<
  PropsWithChildren<{
    team: TeamRolesRole[] | undefined
    hrContact: string | undefined
    label: string | null | undefined
  }>
> = ({ team, hrContact, label }) => {
  const defaultHrContact = 'Anna Marie Rybáčková'
  const hrTeam = team?.find(role => role.primary?.role_name === 'HR')
  const contact =
    hrTeam?.fields?.find(member => member.member_name === hrContact) ||
    hrTeam?.fields?.find(member => member.member_name === defaultHrContact)

  const labelFromLink = (link: string) => link?.replace(/^(?:https?:\/\/)?/i, '')

  return (
    <div
      className="absolute top-0 right-0 hidden h-400 w-45/100 max-w-600 bg-black pt-64 pb-135 xl:block"
      style={{ paddingLeft: 'calc(7%)' }}
    >
      <div className="relative mr-100">
        <div className="mb-40 text-14 tracking-extra-widest text-alto">{label}</div>
        <div className="border-b border-mineShaft py-9">
          <div className="flex items-center tracking-wide">{contact?.member_role}</div>
          <ul className="mb-10 space-y-2 py-16 text-cloud">
            <li className="mb-8 uppercase">{contact?.member_name}</li>
            <li>{contact?.member_email}</li>
            <li>{contact?.member_number}</li>
            <li>
              <a href={contact?.member_link as string} target="_blank" rel="noreferrer">
                {labelFromLink(contact?.member_link!)}
              </a>
            </li>
          </ul>
        </div>
        <div className="absolute top-0 right-0 -mt-115 w-3/7 max-w-170">
          <Image
            src={contact?.member_profile?.url || '/images/profile-placeholder.png'}
            alt={contact?.member_profile?.alt || 'Profile'}
            width={175}
            height={250}
            layout="responsive"
            objectFit="contain"
          />
        </div>
      </div>
    </div>
  )
}

export const ContactPageContactList = (props: { contacts: LayoutGuru[] | null | undefined }) => {
  const [active, setActive] = useState(0)

  const [scrollEnd, setScrollEnd] = useState({
    left: true,
    right: false,
  })

  const scrollRef = useRef<any>(null)

  useEffect(() => {
    function handleScroll(e: any) {
      setScrollEnd(state => ({
        ...state,
        left: !e.currentTarget.scrollLeft,
        right:
          e.currentTarget.scrollWidth ===
          Math.round(e.currentTarget.offsetWidth + e.currentTarget.scrollLeft),
      }))
    }
    scrollRef.current.addEventListener('scroll', handleScroll)
    return () => scrollRef.current?.removeEventListener('scroll', handleScroll)
  }, [])

  const handleClick: ContactItemProps['handleClick'] = index => {
    setActive(index)
  }
  return (
    <>
      <ScrollTo>
        {({ scroll }) => (
          <div className="absolute top-0 right-0 hidden bg-black pb-135 xxxl:block xxxl:w-4/6">
            <div className="relative mx-70">
              <div className="hide-scrollbar relative mt-30 mb-75">
                <div
                  className={cn(
                    'pointer-events-none absolute top-30 left-0 z-10 flex h-250 w-100 translate-y-50 transform bg-gradient-to-r from-black transition-opacity duration-500 sm:w-150',
                    {
                      'opacity-0': scrollEnd.left,
                      'opacity-100': !scrollEnd.left,
                    }
                  )}
                >
                  <button
                    onClick={() =>
                      scrollRef.current &&
                      scroll({
                        ref: { current: scrollRef.current },
                        x: scrollRef.current.scrollLeft - 400,
                        smooth: true,
                      })
                    }
                    className={`my-auto focus:outline-none ${
                      scrollEnd.left ? 'cursor-default' : 'cursor-pointer'
                    }`}
                  >
                    <ArrowRight className="pointer-events-auto w-30 rotate-180 transform fill-current sm:w-55" />
                  </button>
                </div>
                <div
                  ref={scrollRef}
                  className="-mt-170 flex w-full flex-row space-x-45 overflow-x-scroll pt-50"
                >
                  {props.contacts?.map(({ guru }, i) => {
                    const splitEmail: [string, {}] = ['', {}]
                    if (guru.email) {
                      const splittedEmail = guru.email?.split(/(?=\@)/)
                      splitEmail[0] = splittedEmail[0]
                      splitEmail[1] = (
                        <Fragment key={i}>
                          &#8203;
                          {splittedEmail[1]}
                        </Fragment>
                      )
                    }
                    return (
                      <div key={i} className="relative flex-shrink-0 pt-30">
                        <Label className="absolute bottom-305 sm:bottom-365 sm:text-14 xxxl:text-black100">
                          {guru.role_label}
                        </Label>
                        <div className="relative h-200 w-175 sm:h-250">
                          <Image
                            src={guru?.portrait?.url || '/images/profile-placeholder.png'}
                            alt={guru?.portrait?.alt || 'Profile'}
                            layout="fill"
                            objectFit="contain"
                            objectPosition="left"
                          />
                        </div>
                        <div className="mt-14 mb-6 text-12 uppercase tracking-wide sm:text-14">
                          {guru?.name}
                        </div>
                        {Object.keys(splitEmail[1]).length && (
                          <div className="mb-4 text-12 leading-4 text-gray sm:text-14">
                            <>{splitEmail}</>
                          </div>
                        )}
                        <div className="text-12 text-gray sm:text-14">{guru?.phone}</div>
                        {guru.phone_uk && (
                          <div className="text-12 text-gray sm:text-14">{guru?.phone_uk}</div>
                        )}
                      </div>
                    )
                  })}
                </div>
                <div
                  className={cn(
                    'pointer-events-none absolute top-30 right-0 flex h-250 w-100 translate-y-50 translate-x-2 transform bg-gradient-to-l from-black transition-opacity duration-500 sm:w-150',
                    {
                      'opacity-0': scrollEnd.right,
                      'opacity-100': !scrollEnd.right,
                    }
                  )}
                >
                  <button
                    onClick={() =>
                      scroll({
                        ref: scrollRef.current,
                        x: scrollRef.current.scrollLeft + 400,
                        smooth: true,
                      })
                    }
                    className={`my-auto ml-auto focus:outline-none ${
                      scrollEnd.right ? 'cursor-default' : 'cursor-pointer'
                    }`}
                  >
                    <ArrowRight className="pointer-events-auto w-30 fill-current sm:w-55" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </ScrollTo>

      <div
        className="absolute top-0 right-0 hidden h-560 w-45/100 max-w-600 bg-black pt-64 pb-135 xl:block xxxl:hidden"
        style={{ paddingLeft: 'calc(7%)' }}
      >
        <div className="relative mr-100">
          <ul>
            {props.contacts?.map(({ guru }, i) => (
              <ContactItem
                key={i}
                contact={guru}
                active={active}
                index={i}
                handleClick={handleClick}
              />
            ))}
          </ul>
          <div className="absolute top-0 right-0 -mt-115 h-210 w-140">
            <Image
              src={
                props.contacts?.[active]?.guru.portrait?.url || '/images/profile-placeholder.png'
              }
              alt={props.contacts?.[active]?.guru.portrait?.alt || 'Profile'}
              width={140}
              height={210}
              layout="responsive"
              objectFit="contain"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export const ContactList: FC<PropsWithChildren<ContactListProps>> = ({
  label,
  contacts,
  isContactPage,
  isJobOffer,
  team,
  hrContact,
}) => {
  const [active, setActive] = useState(0)

  const handleClick: ContactItemProps['handleClick'] = index => {
    setActive(index)
  }

  if (isContactPage) return <ContactPageContactList contacts={contacts} />

  if (isJobOffer) return <JobOfferContactList team={team} hrContact={hrContact} label={label} />

  return (
    <div
      className="absolute top-0 right-0 hidden h-650 w-45/100 max-w-600 bg-black pt-64 pb-135 xl:block"
      style={{ paddingLeft: 'calc(7%)' }}
    >
      <div className="relative mr-100">
        <div className="mb-40 text-14 tracking-extra-widest text-alto">{label}</div>
        <ul>
          {contacts?.map(({ guru }, i) => (
            <ContactItem
              key={i}
              contact={guru}
              active={active}
              index={i}
              handleClick={handleClick}
            />
          ))}
        </ul>
        <div className="absolute top-0 right-0 -mt-115 w-3/7 max-w-170">
          <Image
            src={contacts?.[active]?.guru.portrait?.url || '/images/profile-placeholder.png'}
            alt={contacts?.[active]?.guru.portrait?.alt || 'Profile'}
            width={175}
            height={250}
            layout="responsive"
            objectFit="contain"
          />
        </div>
      </div>
    </div>
  )
}
