import { LayoutFooter_NavNav_Link_Group, Maybe } from 'types/graphql'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import { Fragment } from 'react'

type Props = {
  linkGroups: Maybe<LayoutFooter_NavNav_Link_Group[]> | undefined
  className?: string
}

export const FooterNav = ({ linkGroups, className }: Props) => {
  return (
    <nav className={className}>
      <div className="-m-8 flex flex-wrap md:m-0 md:flex-col md:flex-nowrap md:items-center md:space-y-16 xl:flex-row xl:justify-center xl:space-y-0">
        {linkGroups?.map(({ fields }, i) => {
          const isFirst = i === 0

          return (
            // group of links
            <Fragment key={i}>
              {!isFirst && (
                <div className="hidden xl:mx-32 xl:block xl:h-24 xl:w-1 xl:flex-shrink-0 xl:bg-tuna" />
              )}
              <div className="flex-1 space-y-8 p-8 md:flex md:flex-none md:space-y-0 md:space-x-32 md:p-0">
                {fields?.map(({ link, label }, i) => (
                  <FlexibleLink
                    data={label === 'Knowledge Hub' ? '/knowledge-hub' : link}
                    className="block w-fit-content"
                    key={i}
                  >
                    {label}
                  </FlexibleLink>
                ))}
              </div>
            </Fragment>
          )
        })}
      </div>
    </nav>
  )
}
