import { Microsite_Landing_PageBodyTestimonyPrimary, PageBodyTestimonyPrimary } from 'types/graphql'
import { Button } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import Fade from 'react-reveal'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import Image from 'next/image'
import cn from 'classnames'
import Logo from 'assets/icons/logo.svg'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  data: Microsite_Landing_PageBodyTestimonyPrimary & PageBodyTestimonyPrimary
}

// TODO: refactor next/image styles, positioning... basically the whole component layout

export const Testimony: FC<PropsWithChildren<Props>> = ({
  data: {
    image,
    image_mobile,
    image_position,
    bg_color,
    text_color_white,
    quote,
    author_profile,
    author_name,
    author_role,
    cta_label,
    cta_link,
    larger_author_labels,
    applifting_logo,
  },
}) => {
  const isLeft = image_position !== 'right'
  return (
    <section className="flex w-full px-15 py-50 lg:px-80 lg:pt-40 lg:pb-100">
      <div className="mx-auto flex w-full max-w-1480 flex-col">
        <Fade>
          <ParallaxWrapper deflection={20} start={5}>
            <div
              className={cn(
                'mx-auto flex w-full max-w-800 lg:h-700 lg:max-w-full xl:h-800',
                isLeft ? 'flex-col lg:flex-row' : 'flex-col lg:flex-row-reverse'
              )}
            >
              <div className="relative w-full lg:max-w-850">
                <div className="h-full w-full lg:hidden">
                  <Image
                    className=""
                    width={image_mobile?.dimensions?.width || image?.dimensions?.width}
                    height={image_mobile?.dimensions?.height || image?.dimensions?.height}
                    src={image_mobile?.url || image?.url}
                    alt={image_mobile?.alt || image?.alt}
                    layout="responsive"
                  />
                </div>
                <div
                  className={cn('h-full w-full', {
                    'hidden lg:block': image_mobile?.url,
                  })}
                >
                  <Image
                    className={cn('h-full w-full', {
                      'lg:object-left': cta_link,
                    })}
                    layout="fill"
                    objectFit="cover"
                    src={image?.url}
                    alt={image?.alt}
                  />
                </div>
              </div>
              <div
                className={cn(
                  'relative z-10 flex justify-center lg:w-full lg:min-w-500 lg:max-w-630',
                  {
                    'items-end': cta_link,
                    'items-center': !cta_link,
                  }
                )}
                style={{ background: bg_color as string }}
              >
                <div
                  className={cn('mx-24 mt-50 lg:mx-48 lg:mt-60', { 'mb-32 lg:mb-60': cta_link })}
                >
                  <div
                    className={cn('mb-32', {
                      'text-white': text_color_white,
                      'lg:mb-80 xl:mb-150': cta_link,
                    })}
                  >
                    <div
                      className={cn(
                        'flex max-w-450 flex-col items-center text-22 lg:max-w-330 lg:items-start lg:text-left lg:text-24 lg:leading-9',
                        {
                          'text-center': !applifting_logo,
                          'mb-40': cta_link,
                          'mb-24 lg:mb-48': !cta_link,
                        }
                      )}
                    >
                      <blockquote className="quote">
                        <RichText render={quote} />
                      </blockquote>
                    </div>
                    <div className="flex flex-row justify-between lg:block lg:space-y-48">
                      <div
                        className={cn('flex w-fit-content items-center lg:w-auto', {
                          'mx-auto lg:mx-0': !applifting_logo,
                        })}
                      >
                        <div className="h-35 w-35 overflow-hidden rounded-full lg:h-70 lg:w-70">
                          <Image
                            src={author_profile?.url}
                            alt={author_profile?.alt}
                            width={70}
                            height={70}
                            layout="responsive"
                            objectFit="contain"
                          />
                        </div>
                        <div className="ml-22 text-left">
                          <div
                            className={cn(
                              'tracking-wider lg:text-14',
                              larger_author_labels ? 'text-12' : 'text-10'
                            )}
                          >
                            {author_name}
                          </div>
                          <div
                            className={cn(
                              'text-opacity-50 lg:text-14',
                              larger_author_labels ? 'text-12 leading-1.3' : 'text-10',
                              text_color_white ? 'opacity-60' : 'text-black100'
                            )}
                          >
                            {typeof author_role === 'object' ? (
                              <RichText render={author_role} />
                            ) : (
                              author_role
                            )}
                          </div>
                        </div>
                      </div>
                      {applifting_logo && <Logo className="w-15 fill-current lg:w-27" />}
                    </div>
                  </div>
                  {cta_link && (
                    <div>
                      <Button href={cta_link} center type="link">
                        {cta_label}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ParallaxWrapper>
        </Fade>
      </div>
    </section>
  )
}
