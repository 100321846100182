import { Case_StudyBodyCs_HeroPrimary } from 'types/graphql'
import { Label } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import { ButtonBack } from 'components/Shared/ButtonBack'
import { ROUTES } from 'utils'
import Image from 'next/image'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  exitLabel: string
  skillsLabel: string
  data: Case_StudyBodyCs_HeroPrimary
}

export const HeroCaseStudy: FC<PropsWithChildren<Props>> = ({
  data: { image, bg_color, label, title, claim, skills },
  exitLabel,
  skillsLabel,
}) => {
  return (
    <>
      <ButtonBack link={ROUTES.our_work} label={exitLabel} />
      <section
        className="relative flex w-full bg-cover px-15 py-50 lg:mb-100 lg:px-80 lg:py-180"
        style={{
          background: `${bg_color}`,
        }}
      >
        <div className="flex w-full flex-col lg:mx-auto">
          <div className="pt-120">
            <div className="max-w-600 lg:mx-auto lg:max-w-1280">
              <Label>{label}</Label>
              <h1 className="heading-strong text-stroke ginto-nord my-30 break-words font-heading text-40 text-transparent lg:text-6xl xl:text-110">
                <RichText render={title} />
              </h1>
            </div>
            <ParallaxWrapper deflection={5} factor={0.9}>
              <div className="mx-auto my-20 max-h-750 w-full max-w-1280 object-contain">
                <Image
                  src={image?.url}
                  alt={image?.alt}
                  width={1280}
                  height={750}
                  layout="responsive"
                  objectFit="contain"
                />
              </div>
            </ParallaxWrapper>
            <div className="md:flex lg:mx-auto lg:max-w-1280">
              <div className="mb-70 flex-1 space-y-20 text-20 leading-relaxed md:mr-150 lg:mb-0 lg:space-y-30 lg:text-30">
                <RichText render={claim} />
              </div>
              <div className="flex-1">
                <Label>{skillsLabel}</Label>
                <div className="mt-20 text-14 leading-loose lg:text-17">
                  <RichText render={skills} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
