import { SectionMain } from 'components/Shared'
import { PageBodyText_ColumnsFields, PageBodyText_ColumnsPrimary } from 'types/graphql'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import { TextColumn } from 'components/Shared/TextColumn'
import cn from 'classnames'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  primary: PageBodyText_ColumnsPrimary
  fields: PageBodyText_ColumnsFields[]
}

export const TextColumns: FC<PropsWithChildren<Props>> = ({
  primary: { label, label_position, four_in_row },
  fields,
}) => {
  return (
    <SectionMain>
      <div
        className={cn('text-14 tracking-extra-widest', label_position && 'text-center uppercase')}
      >
        {label}
      </div>
      <div className="flex flex-row flex-wrap justify-center justify-items-center ">
        {fields.map((column, i) => (
          <ParallaxWrapper
            className={cn('max-h-full min-w-0', four_in_row ? 'max-w-260' : 'max-w-400')}
            deflection={four_in_row ? (i + 1) * 10 : (i + 1) * 23}
            key={i}
          >
            <TextColumn
              heading={column.heading}
              claim={column.claim}
              i={i}
              isColumn={!!four_in_row}
            />
          </ParallaxWrapper>
        ))}
      </div>
    </SectionMain>
  )
}
