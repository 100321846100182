import { PageBodyPartnershipFields, PageBodyPartnershipPrimary } from 'types/graphql'
import { Partner } from 'components/Shared/Partner'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  primary: PageBodyPartnershipPrimary
  fields: PageBodyPartnershipFields[]
}

export const Partnership: FC<PropsWithChildren<Props>> = props => {
  return (
    <section className="relative mx-auto w-full max-w-1480 py-64 px-16 lg:my-100 lg:px-64 xl:my-120">
      <div className="flex flex-col justify-between lg:flex-row">
        <div className="max-w-240 text-30 xs:max-w-400 md:text-48">{props.primary.heading}</div>
        <ul className="w-full max-w-720">
          {props.fields.map((item, i) => (
            <Partner partner={item} key={i} index={i} length={props.fields.length} />
          ))}
        </ul>
      </div>
    </section>
  )
}
