import { RichText, RichTextBlock } from 'prismic-reactjs'
import Logo from 'assets/icons/logo.svg'
import {
  Guru,
  Layout,
  LayoutFooter_Main,
  LayoutFooter_Right,
  LayoutForm,
  LayoutFormContact_FormPrimary,
  TeamRoles,
} from 'types/graphql'
import { FooterForm } from '../../Forms/FooterForm'
import { ContactList, LayoutGuru } from './ContactList'
import { ContactListMobile } from './ContactListMobile'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import { FooterNav } from 'components/Layout/Footer/FooterNav'
import dynamic from 'next/dynamic'
import type { JobOfferFormProps } from 'components/Forms/JobOfferForm'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

const JobOfferForm = dynamic<JobOfferFormProps>(() =>
  import('components/Forms/JobOfferForm').then(mod => mod.JobOfferForm)
)

interface Props {
  footerMain: LayoutFooter_Main[]
  footerRight: LayoutFooter_Right[]
  footerRightText: RichTextBlock[]
  footerNav: Layout['footer_nav']
  footerType?: string
  form: LayoutForm[] | null | undefined
  gurus: LayoutGuru[] | null | undefined
  isContactPage?: boolean
  isJobOffer?: boolean
  jobTitle?: string
  team?: TeamRoles[]
  hrContact?: string
  recruiteeTag?: string
  prioritizedContact: Pick<Guru, 'name'> | null | undefined
}

export const Footer: FC<PropsWithChildren<Props>> = ({
  footerMain,
  footerRight,
  footerRightText,
  footerNav,
  footerType,
  form,
  gurus,
  isContactPage,
  isJobOffer,
  jobTitle,
  team,
  hrContact,
  recruiteeTag,
  prioritizedContact,
}) => {
  const contactFormData = form?.filter(form => form.type === 'contact_form')[0]

  const getSortedContacts = () => {
    const prioritizedContactIndex = gurus?.findIndex(
      contact => contact.guru.name === prioritizedContact?.name
    )
    const sortedContacts =
      prioritizedContactIndex && gurus && ![0, -1].includes(prioritizedContactIndex)
        ? [
            gurus[prioritizedContactIndex],
            ...gurus.slice(0, prioritizedContactIndex),
            ...gurus.slice(prioritizedContactIndex + 1),
          ]
        : gurus

    return sortedContacts
  }

  return (
    <footer className="mt-50 flex flex-shrink-0 flex-col lg:mx-80 lg:mt-120">
      <div className="relative mx-auto flex w-full max-w-1480 flex-col self-center bg-black100 px-16 pt-64 pb-50 text-white sm:px-100 sm:pt-100 sm:pb-72">
        {footerType === 'job' ? (
          <h2>job form</h2>
        ) : (
          <>
            {isContactPage ? (
              <h3 className="heading-strong-white text-stroke-white w-full font-heading text-24 text-transparent lg:text-36 xl:mb-140 xl:w-1/2 xl:text-54 xxxl:w-1/4 xxxl:text-36">
                <strong>Not big fan of forms?</strong>
                <br />
                Talk to us directly.
              </h3>
            ) : isJobOffer ? (
              // TODO: Remove job offer form from PRISMIC!
              <JobOfferForm
                form={contactFormData?.primary as LayoutFormContact_FormPrimary}
                jobTitle={jobTitle}
                recruiteeTag={recruiteeTag}
              />
            ) : (
              <FooterForm form={contactFormData?.primary as LayoutFormContact_FormPrimary} />
            )}

            <ContactList
              label={contactFormData?.primary?.contact_label}
              contacts={getSortedContacts()}
              isContactPage={isContactPage}
              isJobOffer={isJobOffer}
              team={team}
              hrContact={hrContact}
            />
            <ContactListMobile
              contacts={getSortedContacts()}
              isJobOffer={isJobOffer}
              team={team}
              hrContact={hrContact}
            />
          </>
        )}
        <div className="mt-110 flex flex-col lg:mt-150">
          <div className="space-y-64 text-14 leading-10 text-darkgray xxs:grid xxs:grid-cols-2 xxs:gap-y-80 xxs:gap-x-20 xxs:space-y-0 sm:gap-x-36 md:grid-cols-3 md:gap-y-36">
            {footerMain.map((column, i) => (
              <div
                key={i}
                className="tracking-widest sm:grid"
                style={{ gridTemplateRows: '90px auto 90px' }}
              >
                <h4 className="font-normal tracking-wider text-white">{column.city}</h4>
                <div className="my-36 leading-8 sm:my-0">
                  <RichText render={column.address} />
                </div>
                <div className="flex flex-col space-y-10 sm:self-end xxl:flex-row xxl:space-y-0 xxl:space-x-30">
                  {column.contact.map((item: { text: string }, i: number) => (
                    <div key={i} className="whitespace-nowrap leading-4">
                      {item.text}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div
              style={{ gridTemplateRows: '90px auto 90px' }}
              className="relative flex flex-col pl-64 text-darkgray xs:col-span-2 sm:col-span-1 md:grid md:grid-rows-3 md:pl-0"
            >
              <Logo className="absolute left-0 top-4 w-30 fill-current text-white md:static" />
              <div className="mb-16 text-12 md:text-14 md:leading-8 xl:mb-0">
                {footerRight.map((item, i) => (
                  <FlexibleLink data={item.link} key={i}>
                    {item.label}
                  </FlexibleLink>
                ))}
              </div>
              <div className="ul-flex li-margin whitespace-nowrap text-12 md:self-end md:leading-4 xl:mr-0">
                <RichText render={footerRightText} />
              </div>
            </div>
          </div>
          <FooterNav linkGroups={footerNav} className="mt-84" />
        </div>
      </div>
      <div className="relative mx-auto flex w-full max-w-1480 flex-col space-y-16 border-t border-tuna bg-black100 px-16 pt-64 pb-50 text-14 text-darkgray xs:flex-row xs:space-y-0 xs:space-x-16 sm:space-x-40 sm:px-100 md:space-x-80">
        <div>
          APPLIFTING&nbsp;S.R.O.
          <br />
          Rohanské nábřeží&nbsp;670/19, Praha&nbsp;8
        </div>
        <div>
          IČO&nbsp;02542072
          <br />
          DIČ&nbsp;CZ02542072
        </div>
        <div>
          Městský soud v&nbsp;Praze,
          <br />
          Vložka&nbsp;220046&nbsp;C
        </div>
      </div>
    </footer>
  )
}
