import { FC, useState } from 'react'
import Plus from 'assets/icons/menu_plus.svg'
import Minus from 'assets/icons/menu_minus.svg'
import AnimateHeight from 'react-animate-height'
import cn from 'classnames'
import { Maybe } from 'types/graphql'
import { RichText } from 'prismic-reactjs'
import type { PropsWithChildren } from 'react'

interface Props {
  number: number
  claim: any
  heading: Maybe<string> | undefined
  isLastItem: boolean
}

export const AccordionItem: FC<PropsWithChildren<Props>> = ({
  number,
  claim,
  heading,
  isLastItem,
}) => {
  const [toggleActive, setToggleActive] = useState(false)

  const handleClick = () => {
    setToggleActive(!toggleActive)
  }
  return (
    <li
      className={cn(
        'cursor-pointer border-alto py-20 sm:py-40',
        isLastItem ? 'border-b-0' : 'border-b'
      )}
      onClick={handleClick}
    >
      <div className="relative flex flex-row md:items-center">
        <div className="text-14 tracking-extra-widest">{'0' + (number + 1)}</div>
        <div className="px-20 text-30 sm:px-40 sm:text-32">{heading}</div>
        <div className="absolute right-0">{toggleActive ? <Minus /> : <Plus />}</div>
      </div>
      <AnimateHeight duration={500} height={toggleActive ? 'auto' : 0}>
        <div className={'mt-20 flex justify-end text-17 leading-8 sm:mt-40'}>
          <div className="w-full px-40 sm:px-80">
            <RichText render={claim} />
          </div>
        </div>
      </AnimateHeight>
    </li>
  )
}
