import { FC, useEffect, useState } from 'react'
import { _FileLink, Case_StudyBodyClaims_With_BgPrimary } from 'types/graphql'
import { Label } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import Fade from 'react-reveal'
import cn from 'classnames'
import Checkmark from 'assets/icons/checkmark.svg'
import axios from 'axios'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import type { LottieProps } from 'react-lottie'
import type { PropsWithChildren } from 'react'

const Lottie = dynamic<LottieProps>(() => import('react-lottie'))

export interface ClaimsWithBgProps {
  data: Case_StudyBodyClaims_With_BgPrimary
}

export const ClaimsWithBg: FC<PropsWithChildren<ClaimsWithBgProps>> = ({
  data: { label, heading, claim, background, image, image_position, checked_columns, video },
}) => {
  const [data, setData] = useState({})
  useEffect(() => {
    const videoData = video as _FileLink

    const fetchJSON = async (input: string) => {
      const result = await axios(input)
      setData(result.data)
    }

    videoData?.url && fetchJSON(videoData.url)
  }, [video])

  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: 'xMaxYMin meet',
    },
  }

  return (
    <section
      className="w-full max-w-1480 px-16 pt-48 pb-95 lg:my-60 lg:px-80 xl:py-120"
      style={{ background: background as string }}
    >
      <Fade bottom>
        <div className={cn('mx-auto max-w-1100', { 'lg:flex': image_position === 'right' })}>
          <div className="flex-1">
            {image_position === 'right' ? (
              <div className="lg:max-w-350 xl:max-w-400">
                <Label className="w-full pr-32 lg:w-auto lg:pr-0" newLineAferColon={true}>
                  {label}
                </Label>
                <h2 className="my-40 text-30 font-light sm:text-48">{heading}</h2>
                <div className="mb-80 space-y-20 text-17 leading-9 xl:mb-0">
                  <RichText render={claim} />
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-col lg:mx-auto lg:max-w-900 lg:items-center">
                  <Label newLineAferColon={true} className="text-center">
                    {label}
                  </Label>
                  {(heading || RichText.asText(claim)) && (
                    <div className="mt-40 lg:mt-70 lg:flex">
                      <h2 className="mb-48 font-light lg:min-w-350">{heading}</h2>
                      <div className="text-17 leading-8 lg:ml-100 lg:mt-90">
                        <RichText render={claim} />
                      </div>
                    </div>
                  )}
                </div>
                {checked_columns && (
                  <div className="mx-auto mt-55 flex max-w-1200 flex-shrink-0 flex-wrap justify-center text-17 sm:mt-110">
                    {checked_columns.map(
                      (
                        column: Case_StudyBodyClaims_With_BgPrimary['checked_columns'],
                        i: number
                      ) => (
                        <div
                          key={i}
                          className="mx-20 my-18 flex items-center space-x-32 sm:my-24 sm:space-x-45"
                        >
                          <Checkmark />
                          <div className="richtext-strong w-220">
                            <RichText render={[column]} />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="pointer-events-none mx-auto mt-55 w-full flex-1 sm:mt-80">
            {!video ? (
              <div className="mx-auto w-full">
                <Image
                  src={image?.url}
                  alt={image?.alt}
                  width={550}
                  height={550}
                  layout="responsive"
                  objectFit="contain"
                />
              </div>
            ) : (
              <Lottie
                options={defaultLottieOptions}
                isStopped={false}
                isPaused={false}
                isClickToPauseDisabled={true}
              />
            )}
          </div>
        </div>
      </Fade>
    </section>
  )
}
