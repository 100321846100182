import { Button } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import {
  Job_OfferBodyCta_Columns_BgFields,
  Job_OfferBodyCta_Columns_BgPrimary,
} from 'types/graphql'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  data: {
    primary: Job_OfferBodyCta_Columns_BgPrimary
    fields: Job_OfferBodyCta_Columns_BgFields[]
  }
}

export const CtaColumnsBg: FC<PropsWithChildren<Props>> = ({
  data: {
    primary: { bg_color, heading_strong, cta_label, cta_link, image },
    fields,
  },
}) => (
  <section
    className="relative mx-auto w-full max-w-1480 py-64 px-16 lg:my-100 lg:px-64 xl:my-120"
    style={{ background: bg_color as string }}
  >
    <img
      src={image.url}
      alt={image.alt}
      className="absolute hidden md:top-32 md:right-0 md:block md:w-300 lg:top-64 lg:right-80 lg:w-365"
    />
    <div className="relative mx-auto max-w-1000 md:pr-24 lg:pr-70">
      <div className="heading-strong text-stroke relative mb-32 font-heading text-transparent children:text-36 children:first:max-w-500 xs:children:text-40 md:mb-60 md:children:text-64 lg:max-w-550">
        <RichText render={heading_strong} />
        <img
          src={image.url}
          alt={image.alt}
          className="absolute right-0 w-256 translate-x-16 transform opacity-75 xs:w-300 sm:opacity-100 md:hidden"
        />
      </div>
      <div className="relative z-10 mb-50 max-w-350 space-y-44 text-17 leading-8 md:grid md:max-w-full md:grid-flow-col md:gap-x-40 md:space-y-0 lg:gap-x-64">
        {fields.map((field, i) => (
          <div key={i} className="cta-columns-bg-link">
            <RichText render={field.claim} />
          </div>
        ))}
      </div>
      <Button
        type="link"
        href={cta_link}
        customClass="flex-col children:ml-0 children:mt-6 sm:flex-row sm:children:ml-20 sm:children:mt-0"
        noWrap
        whitespaceWrap
        alignLeftMobile
      >
        {cta_label}
      </Button>
    </div>
  </section>
)
