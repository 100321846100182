import { Job_OfferBodyListFields, Job_OfferBodyListPrimary } from 'types/graphql'
import Bullet from '../assets/icons/bullet.svg'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  data: {
    fields: Job_OfferBodyListFields[]
    primary: Job_OfferBodyListPrimary
  }
}

export const ListWithTitle: FC<PropsWithChildren<Props>> = ({
  data: {
    primary: { title_text },
    fields,
  },
}) => (
  <div className="flex-cols flex w-full max-w-1480 px-15 lg:mx-auto lg:items-center lg:justify-between lg:px-80">
    <div className="mt-40 flex-row lg:mt-70 lg:flex lg:w-full">
      <h3 className="mb-24 flex flex-1 font-light md:text-24">{title_text}</h3>
      <div className="flex flex-1 text-18 leading-8 lg:ml-40">
        <ul>
          {fields.map((field, i) => (
            <li className="mb-16 flex flex-row text-16" key={i}>
              {' '}
              <div className="mt-10 w-40">
                <Bullet />
              </div>{' '}
              <div className="flex flex-1">{field.list_item}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
)
