import { FC } from 'react'
import { Microsite_Landing_PageBodyArticles_HeadingPrimary } from '../types/graphql'
import { RichText } from 'prismic-reactjs'
import { SectionMain } from '../components/Shared'
import type { PropsWithChildren } from 'react'

interface Props {
  primary: Microsite_Landing_PageBodyArticles_HeadingPrimary
}

export const ArticlesHeading: FC<PropsWithChildren<Props>> = ({ primary: { heading_text } }) => {
  return (
    <SectionMain noMarginY className="mt-80 sm:mt-100">
      <h2 className="text-stroke heading-strong font-heading text-40 text-transparent sm:text-48">
        <RichText render={heading_text} />
      </h2>
    </SectionMain>
  )
}
