import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { Button, Label } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import { PageBodyPhasesFields, PageBodyPhasesPrimary } from 'types/graphql'
import AnimateHeight from 'react-animate-height'
import cn from 'classnames'
import Plus from 'assets/icons/menu_plus.svg'
import Minus from 'assets/icons/menu_minus.svg'
import Fade from 'react-reveal'
import { useWindowSize } from 'utils/useWindowSize'
import Image from 'next/image'
import type { PropsWithChildren } from 'react'

interface ColumnsDesktopProps {
  columns: PageBodyPhasesFields[]
}

interface ColumnsMobileProps {
  columns: PageBodyPhasesFields[]
}

interface ColumnsMobileItemProps {
  item: PageBodyPhasesFields
  active: number
  index: number
  handleClick: (index: number) => void
}

interface PhasesProps {
  primary: PageBodyPhasesPrimary
  fields: PageBodyPhasesFields[]
}

const ColumnsDesktop: FC<PropsWithChildren<ColumnsDesktopProps>> = ({ columns }) => (
  <div className="mb-36 md:mb-0 xl:min-w-400">
    <ul className="mt-10 md:space-y-36">
      {columns.map((column, i) => (
        <li key={i} className="md:space-y-12 xl:grid xl:grid-cols-2 xl:gap-x-24 xl:space-y-0">
          <Label>{column.column_label}</Label>
          <ul className="space-y-9 text-17 leading-6">
            {column.column_items.map((item: { text: string }, i: number) => (
              <li key={i}>{item.text}</li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  </div>
)

const ColumnsMobile: FC<PropsWithChildren<ColumnsMobileProps>> = ({ columns }) => {
  const [active, setActive] = useState(0)

  const handleClick: ColumnsMobileItemProps['handleClick'] = index => {
    setActive(index)
  }

  return (
    <div className="mb-64">
      <div className="relative">
        <ul>
          {columns.map((item, i) => (
            <ColumnsMobileItem
              key={i}
              item={item}
              active={active}
              index={i}
              handleClick={handleClick}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

const ColumnsMobileItem: FC<PropsWithChildren<ColumnsMobileItemProps>> = ({
  item,
  active,
  index,
  handleClick,
}) => {
  const [height, setHeight] = useState<'auto' | number>('auto')
  const [initialHeight, setInitialHeight] = useState<'auto' | number>('auto')

  const itemsRef = useRef() as MutableRefObject<HTMLUListElement>

  useEffect(() => {
    itemsRef.current && setInitialHeight(itemsRef.current.offsetHeight)
  }, [itemsRef.current])

  useEffect(() => {
    if (index === active) {
      setHeight(initialHeight)
    } else {
      setHeight(0)
    }
  }, [active])

  return (
    <li
      className="cursor-pointer border-b border-black py-24 text-bronzeOlive"
      onClick={() => handleClick(index)}
    >
      <p className="flex items-center justify-between text-14 uppercase tracking-extra-wide">
        {item.column_label}
        {active === index ? (
          <Minus className="">{index === active ? '–' : '+'}</Minus>
        ) : (
          <Plus className="">{index === active ? '–' : '+'}</Plus>
        )}
      </p>
      <AnimateHeight
        height={height}
        className={cn(active === index && 'mt-24')}
        style={{ transition: 'margin 200ms ease' }}
        duration={200}
      >
        <ul ref={itemsRef} className="space-y-8 text-17">
          {item.column_items.map((row: { text: string }, i: number) => (
            <li key={i} className="leading-8">
              {row.text}
            </li>
          ))}
        </ul>
      </AnimateHeight>
    </li>
  )
}

export const Phases: FC<PropsWithChildren<PhasesProps>> = ({
  primary: { image, bg_color, heading_strong, cta_label, cta_link },
  fields,
}) => {
  const { width } = useWindowSize()
  return (
    <section className="my-50 w-full lg:my-100 lg:px-80 ">
      <Fade bottom>
        <div className="mx-auto w-full max-w-1280">
          <div
            className="relative flex flex-col p-16 md:flex-row md:p-64 xl:justify-between xl:p-100"
            style={{ backgroundColor: bg_color as string | undefined }}
          >
            <div className="md:w-2/3 md:pr-64 xl:mb-350 xl:w-half xl:pr-40">
              <div className="h2-larger text-stroke mb-36 font-heading text-transparent">
                <RichText render={heading_strong} />
              </div>
              <Button type="link" href={cta_link} noWrap customClass="mb-70 xl:mb-0" center>
                {cta_label}
              </Button>
            </div>
            {!!width && width >= 768 ? (
              <ColumnsDesktop columns={fields} />
            ) : (
              <ColumnsMobile columns={fields} />
            )}
            <div className="relative top-16 right-16 w-screen max-w-550 md:absolute md:right-auto md:top-auto md:bottom-0 md:left-0 md:w-half md:min-w-450 md:max-w-full lg:max-w-500 xl:left-100 xl:max-w-550">
              <Image
                src={image?.url}
                alt={image?.alt}
                width={550}
                height={370}
                layout="responsive"
                objectFit="contain"
              />
            </div>
          </div>
        </div>
      </Fade>
    </section>
  )
}
