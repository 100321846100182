import { Label } from 'components/Shared'
import { PrismicImage } from 'types/util'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

export enum AlignEnum {
  left = 'left',
  right = 'right',
}

interface Props {
  title: string
  description?: string
  image: PrismicImage
  url: string
}

export const CaseStudySliderItem: FC<PropsWithChildren<Props>> = ({
  title,
  description,
  image,
  url,
}) => {
  return (
    <FlexibleLink data={url}>
      <div className="flex flex-col">
        <div className="slider-image">
          <img src={image?.url} alt={image?.alt || ''} className="h-full w-full object-cover" />
        </div>

        {description && <Label className="mt-24 mb-8">{title}</Label>}
        <p className="max-w-3/4 text-19 sm:text-20 md:text-32">{description || title}</p>
      </div>
    </FlexibleLink>
  )
}
