import { RichText } from 'prismic-reactjs'
import { FC } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { PageBodyStoryFields, PageBodyStoryPrimary } from 'types/graphql'
import { useWindowSize } from 'utils/useWindowSize'
import type { PropsWithChildren } from 'react'

interface Props {
  primary: PageBodyStoryPrimary
  fields: PageBodyStoryFields[]
}

export const Story: FC<PropsWithChildren<Props>> = ({
  primary: { heading_strong, bg_color },
  fields,
}) => {
  const windowSize = useWindowSize()
  const getColumnHeight = <T extends PageBodyStoryFields['position']>(
    height: T,
    height_lg: T
  ): string =>
    100 -
    (((windowSize.width as number) < 1024 ? height : height_lg ? height_lg : height) as number) +
    '%'

  return (
    <section className="my-50 flex w-full pl-15 lg:my-100 lg:pl-80 xxxl:pr-80">
      <div className="relative flex w-full xxxl:justify-center">
        <div className="w-full xxxl:max-w-1480" style={{ background: bg_color as string }}>
          <ScrollContainer className="flex h-450 w-full cursor-grab overflow-x-auto pl-20 xxs:h-585 lg:h-900 lg:pl-100">
            <div className="relative flex flex-shrink-0">
              <div className="heading-strong absolute top-30 font-heading text-black100 children:text-40 xxs:top-36 md:children:text-64 lg:top-100">
                <RichText render={heading_strong} />
              </div>
              {fields.map((field, i) => (
                <div
                  key={i}
                  className="flex w-155 items-end border-l border-white border-opacity-25 last:w-250 lg:w-220"
                >
                  <div
                    className="w-full"
                    style={{ height: getColumnHeight(field.position, field.position_big) }}
                  >
                    <div className="relative flex h-full flex-col justify-between px-6 pb-20 lg:px-8 lg:pb-90">
                      {field.logo && (
                        <div className="absolute top-0">
                          <img
                            src={field.logo?.url}
                            alt={field.logo?.alt}
                            className="absolute bottom-20 max-w-100 lg:max-w-150"
                          />
                        </div>
                      )}
                      <div className="relative">
                        <div className="list-dash text-15">
                          <RichText render={field.claim} />
                        </div>
                        <div className="heading-strong-white text-stroke-white -translate-x-9 transform font-heading text-60 text-transparent lg:-translate-x-14 lg:text-110">
                          <div className="relative z-10">
                            <RichText render={field.big_text} />
                          </div>
                          <img
                            src={field.image?.url}
                            alt={field.image?.alt}
                            className="relative max-w-180 translate-x-9 transform xxs:max-w-265 lg:bottom-70 lg:max-w-305 lg:translate-x-14"
                          />
                        </div>
                      </div>
                      <div className="text-17 text-white">{field.year}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="relative w-20 min-w-20 lg:w-90 lg:min-w-90 xxxl:hidden"></div>
          </ScrollContainer>
        </div>
      </div>
    </section>
  )
}
