import { SectionMain } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import { FC } from 'react'
import { PageBodyLarge_HeadingPrimary } from 'types/graphql'
import { prismicHtmlSerializer } from 'utils'
import type { PropsWithChildren } from 'react'

interface Props {
  primary: PageBodyLarge_HeadingPrimary
}

export const LargeHeading: FC<PropsWithChildren<Props>> = ({ primary }) => {
  return (
    <SectionMain>
      <div className="text-stroke heading-strong heading-link h2-mega font-heading text-white sm:mx-auto sm:w-fit-content">
        <RichText render={primary.large_heading_text} htmlSerializer={prismicHtmlSerializer} />
      </div>
    </SectionMain>
  )
}
