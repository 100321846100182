import { FC, useEffect, useState } from 'react'
import Arrow from 'assets/icons/arrow.svg'
import cn from 'classnames'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import { PrismicLink } from 'types/util'
import type { PropsWithChildren } from 'react'

interface Props {
  link: PrismicLink | string
  label: string
}

export const ButtonBack: FC<PropsWithChildren<Props>> = props => {
  const [backButtonState, setBackButtonState] = useState({
    hasScrolled: false,
    scrollingUp: false,
  })

  useEffect(() => {
    let scrollPosition = 0

    function handleScroll() {
      setBackButtonState(state => ({
        ...state,
        hasScrolled: 18 < window.scrollY,
        scrollingUp: scrollPosition > window.pageYOffset,
      }))

      scrollPosition = window.pageYOffset
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div
      className={cn(
        'group absolute left-15 top-110 z-10 flex transition-all duration-500 lg:fixed lg:left-25',
        backButtonState.hasScrolled && !backButtonState.scrollingUp
          ? 'lg:invisible lg:opacity-0'
          : 'lg:opacity-1 lg:visible'
      )}
    >
      <FlexibleLink data={props.link} className="relative flex items-center">
        <div className="mr-16 w-40 overflow-hidden lg:mr-32 lg:w-80 lg:overflow-visible">
          <Arrow className="rotate-180 transform group-hover:animate-arrowBounceLeft" />
        </div>
        <span className="whitespace-nowrap font-heading uppercase tracking-wider group-hover:animate-textScaleAndHide">
          {props.label}
        </span>
      </FlexibleLink>
    </div>
  )
}
