import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import { Label, SectionMain } from 'components/Shared'
import Image from 'next/image'
import { RichText } from 'prismic-reactjs'
import { FC } from 'react'
import { ServiceBodyPicture_ColumnsFields, ServiceBodyPicture_ColumnsPrimary } from 'types/graphql'
import cn from 'classnames'
import type { PropsWithChildren } from 'react'

interface Props {
  primary: ServiceBodyPicture_ColumnsPrimary
  fields: ServiceBodyPicture_ColumnsFields[]
}

export const PictureColumns: FC<PropsWithChildren<Props>> = ({ primary: { label }, fields }) => {
  return (
    <SectionMain className="py-64">
      <Label className="mx-auto max-w-380 text-center" newLineAferColon>
        {label}
      </Label>
      <div
        className={cn(
          'mt-100 grid justify-items-center gap-y-100 sm:mt-80 sm:grid-cols-2',
          fields.length === 3 ? 'xl:grid-cols-3' : 'xl:grid-cols-4'
        )}
      >
        {fields.map(({ image, column_title, column_text }, i) => (
          <ParallaxWrapper key={i} deflection={(i + 1) * 12}>
            <div
              className={cn(
                'flex max-w-280 flex-col items-center space-y-32 text-center sm:max-w-220 sm:items-start sm:text-left',
                fields.length === 3 && 'xl:max-w-320'
              )}
            >
              <div className="w-full max-w-175 sm:max-w-full">
                <Image
                  src={image.url}
                  alt={image.alt}
                  width={220}
                  height={220}
                  objectFit="contain"
                  layout="responsive"
                />
              </div>
              <div className="text-36 xl:text-42">
                <RichText render={column_title} />
              </div>
              <div className="text-17">
                <RichText render={column_text} />
              </div>
            </div>
          </ParallaxWrapper>
        ))}
      </div>
    </SectionMain>
  )
}
