import { SectionMain } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import { ArticleBodyArticle_Content } from 'types/graphql'
import { prismicHtmlSerializer } from 'utils'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

export const ArticleContent: FC<PropsWithChildren<ArticleBodyArticle_Content>> = props => (
  <SectionMain>
    <div className="blog-content mx-auto max-w-720 text-21">
      <RichText render={props.primary?.content} htmlSerializer={prismicHtmlSerializer} />
    </div>
  </SectionMain>
)
