import { FC, useEffect, useRef, useState } from 'react'
import { ScrollTo } from 'react-scroll-to'
import { Label, SectionMain } from 'components/Shared'
import { PageBodyTeam_SmallPrimary, TeamRoles } from 'types/graphql'
import ArrowRight from 'assets/icons/arrow_small_right.svg'
import type { PropsWithChildren } from 'react'

interface Props {
  data: PageBodyTeam_SmallPrimary
  team: TeamRoles[]
}

export const TeamSmall: FC<PropsWithChildren<Props>> = ({ data: { heading }, team }) => {
  const [scrollEnd, setScrollEnd] = useState({
    left: false,
    right: true,
  })

  const allMembers = team.map(a => a.fields?.map(b => b)[0])

  const scrollRef = useRef<any>(null)

  useEffect(() => {
    const handleScroll = (e: any) => {
      setScrollEnd(state => ({
        ...state,
        left: e.target.scrollWidth === e.target.offsetWidth + e.target.scrollLeft,
        right: !e.target.scrollLeft,
      }))
    }
    scrollRef.current?.addEventListener('scroll', handleScroll)
    return () => scrollRef.current?.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <ScrollTo>
      {({ scroll }) => (
        <SectionMain>
          <Label>{heading}</Label>
          <div className="relative mt-30">
            {!scrollEnd.right && (
              <div className="absolute top-0 left-0 flex h-full w-100 bg-gradient-to-r from-white lg:w-150">
                <button
                  onClick={() =>
                    scroll({
                      ref: scrollRef.current,
                      x: scrollRef.current.scrollLeft - 400,
                      smooth: true,
                    })
                  }
                  className="my-auto focus:outline-none"
                >
                  <ArrowRight className="w-30 rotate-180 transform lg:w-55" />
                </button>
              </div>
            )}
            <div ref={scrollRef} className="flex overflow-x-scroll pb-10">
              {allMembers.map((member, i) => (
                <div key={i} className="mr-15 w-155 flex-shrink-0 lg:mr-40 lg:w-175">
                  <div className="h-200 lg:h-250">
                    <img
                      className="h-full w-full object-cover"
                      src={member?.member_profile.url}
                      alt={member?.member_profile.alt}
                    />
                  </div>
                  <div className="mt-10 text-12 uppercase tracking-wide lg:text-14">
                    {member?.member_name}
                  </div>
                  <div className="text-12 text-gray lg:text-14">{member?.member_role}</div>
                </div>
              ))}
            </div>
            {!scrollEnd.left && (
              <div className="absolute top-0 right-0 flex h-full w-100 bg-gradient-to-l from-white lg:w-150">
                <button
                  onClick={() =>
                    scroll({
                      ref: scrollRef.current,
                      x: scrollRef.current.scrollLeft + 400,
                      smooth: true,
                    })
                  }
                  className="my-auto ml-auto focus:outline-none"
                >
                  <ArrowRight className="w-30 lg:w-55" />
                </button>
              </div>
            )}
          </div>
        </SectionMain>
      )}
    </ScrollTo>
  )
}
