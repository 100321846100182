import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { ScrollTo } from 'react-scroll-to'
import { Label } from 'components/Shared'
import ArrowRight from 'assets/icons/arrow_right_thinner.svg'
import { LayoutGuru } from './ContactList'
import * as smoothscroll from 'smoothscroll-polyfill'
import Image from 'next/image'
import { TeamRoles } from 'types/graphql'
import type { PropsWithChildren } from 'react'

interface Props {
  contacts: LayoutGuru[] | null | undefined
  isJobOffer?: boolean
  team?: TeamRoles[]
  hrContact?: string
}

export const ContactListMobile: FC<PropsWithChildren<Props>> = props => {
  const defaultHrContact = 'Anna Marie Rybáčková'
  const hrTeam = props.team?.find(role => role.primary?.role_name === 'HR')
  const hrContact =
    hrTeam?.fields?.find(member => member.member_name === props.hrContact) ||
    hrTeam?.fields?.find(member => member.member_name === defaultHrContact)

  const labelFromLink = (link: string) => link?.replace(/^(?:https?:\/\/)?/i, '')

  const [scrollEnd, setScrollEnd] = useState({
    left: true,
    right: false,
  })

  const scrollRef = useRef<any>(null)

  useEffect(() => {
    smoothscroll.polyfill()
    function handleScroll(e: any) {
      setScrollEnd(state => ({
        ...state,
        left: !e.target.scrollLeft,
        right: e.target.scrollWidth === Math.round(e.target.offsetWidth + e.target.scrollLeft),
      }))
    }
    scrollRef.current?.addEventListener('scroll', handleScroll)
    return () => scrollRef.current?.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      {props.isJobOffer ? (
        <div className="relative mr-15 w-155 flex-shrink-0 sm:mr-40 sm:w-175 xl:hidden">
          <Label className="mb-10 text-12 sm:text-14">{hrContact?.member_role}</Label>
          <div className="h-220 sm:h-250">
            <div className="h-full w-full object-cover object-top">
              <Image
                src={hrContact?.member_profile?.url || '/images/profile-placeholder.png'}
                alt={hrContact?.member_profile?.alt || 'Profile'}
                width={175}
                height={250}
                layout="responsive"
                objectFit="contain"
              />
            </div>
          </div>
          <div className="mt-22 mb-6 text-12 uppercase tracking-wide sm:text-14">
            {hrContact?.member_name}
          </div>
          <div className="mb-4 text-12 leading-4 text-gray sm:text-14">
            {hrContact?.member_email}
          </div>
          <div className="text-12 text-gray sm:text-14">{hrContact?.member_number}</div>
          <div className="text-12 text-gray sm:text-14">
            <a href={hrContact?.member_link as string} target="_blank" rel="noreferrer">
              {labelFromLink(hrContact?.member_link!)}
            </a>
          </div>
        </div>
      ) : (
        <ScrollTo>
          {({ scroll }) => (
            <div className="hide-scrollbar relative mt-30 mb-75 xl:hidden">
              <div
                className={`pointer-events-none absolute top-0 -left-1 z-10 flex h-full w-100 bg-gradient-to-r from-black100 transition-opacity duration-500 sm:w-150 ${
                  scrollEnd.left ? 'opacity-0' : 'opacity-100'
                }`}
              >
                <button
                  onClick={() =>
                    scroll({
                      ref: scrollRef.current,
                      x: scrollRef.current.scrollLeft - 400,
                      smooth: true,
                    })
                  }
                  className={`my-auto focus:outline-none ${
                    scrollEnd.left ? 'cursor-default' : 'cursor-pointer'
                  }`}
                >
                  <ArrowRight className="pointer-events-auto w-30 rotate-180 transform fill-current sm:w-55" />
                </button>
              </div>
              <div ref={scrollRef} className="flex overflow-x-scroll pb-10 pt-120">
                {props.contacts?.map(({ guru }, i) => {
                  const splitEmail: [string, {}] = ['', {}]
                  if (guru.email) {
                    const splittedEmail = guru.email?.split(/(?=\@)/)
                    splitEmail[0] = splittedEmail[0]
                    splitEmail[1] = (
                      <Fragment key={i}>
                        &#8203;
                        {splittedEmail[1]}
                      </Fragment>
                    )
                  }

                  return (
                    <div key={i} className="relative mr-15 w-155 flex-shrink-0 sm:mr-40 sm:w-175">
                      <Label className="absolute bottom-350 sm:bottom-380 sm:max-w-135 sm:text-14">
                        {guru.role_label}
                      </Label>
                      <div className="h-220 sm:h-250">
                        <div className="h-full w-full object-cover object-top">
                          <Image
                            src={guru.portrait?.url || '/images/profile-placeholder.png'}
                            alt={guru.portrait?.alt || 'Profile'}
                            width={175}
                            height={250}
                            layout="responsive"
                            objectFit="contain"
                          />
                        </div>
                      </div>
                      <div className="mt-22 mb-6 text-12 uppercase tracking-wide sm:text-14">
                        {guru.name}
                      </div>
                      {Object.keys(splitEmail[1]).length && (
                        <div className="mb-4 text-12 leading-4 text-gray sm:text-14">
                          <>{splitEmail}</>
                        </div>
                      )}
                      <div className="text-12 text-gray sm:text-14">{guru.phone}</div>
                      {guru.phone_uk && (
                        <div className="text-12 text-gray sm:text-14">{guru.phone_uk}</div>
                      )}
                    </div>
                  )
                })}
              </div>
              <div
                className={`pointer-events-none absolute top-0 -right-1 flex h-full w-100 bg-gradient-to-l from-black100 transition-opacity duration-500 sm:w-150 ${
                  scrollEnd.right ? 'opacity-0' : 'opacity-100'
                }`}
              >
                <button
                  onClick={() =>
                    scroll({
                      ref: scrollRef.current,
                      x: scrollRef.current.scrollLeft + 400,
                      smooth: true,
                    })
                  }
                  className={`my-auto ml-auto focus:outline-none ${
                    scrollEnd.right ? 'cursor-default' : 'cursor-pointer'
                  }`}
                >
                  <ArrowRight className="pointer-events-auto w-30 fill-current sm:w-55" />
                </button>
              </div>
            </div>
          )}
        </ScrollTo>
      )}
    </>
  )
}
