import { FC, Fragment, memo, useEffect, useRef, useState } from 'react'
import { Label, SectionMain } from 'components/Shared'
import {
  PageBodyClaims_With_AnimationFields,
  PageBodyClaims_With_AnimationPrimary,
} from 'types/graphql'
import { RichText } from 'prismic-reactjs'
import cn from 'classnames'
import { useWindowSize } from 'utils/useWindowSize'
import LogoA from 'assets/images/logo-a.svg'
import type { PropsWithChildren } from 'react'

const StyledLogo: FC<PropsWithChildren<{ className?: string }>> = ({ className }) => (
  <LogoA className={cn('stroke-2', className)} />
)

const MovingImage: FC<PropsWithChildren<any>> = memo(
  ({ image, breakpoints, allActive, active, i }) => {
    return (
      <div
        className={cn('pointer-events-none fixed top-half h-480 w-265 -translate-y-half transform')}
        style={{ left: breakpoints[1]?.left, visibility: allActive === 1 ? 'visible' : 'hidden' }}
      >
        {<StyledLogo className={cn({ invisible: i !== 0 })} />}
        <img
          src={image?.url}
          alt={image?.alt}
          className={cn('object-contain', { 'animate-scaleUpCenter': active })}
          style={{
            opacity: active ? 1 : 0,
            transition: active ? 'opacity 0.5s ease' : 'none',
          }}
        />
      </div>
    )
  }
)
const StaticImage: FC<PropsWithChildren<any>> = memo(({ fields, imageRefs, i, allActive }) => {
  if (i === 0) {
    return (
      <div
        className={cn(
          'absolute top-half h-480 w-265 -translate-y-half transform',
          allActive() === 0 ? 'visible' : 'invisible'
        )}
        ref={(el: HTMLDivElement) => (imageRefs.current[i] = el)}
      >
        <StyledLogo />
        <img
          src={fields[i].image?.url}
          alt={fields[i].image?.alt}
          className={cn('w-full object-contain')}
        />
      </div>
    )
  } else if (i === fields.length - 1) {
    return (
      <>
        <div
          className="invisible absolute top-0 h-480 w-265 -translate-y-half transform"
          ref={(el: HTMLDivElement) => (imageRefs.current[i] = el)}
        >
          <StyledLogo />
          <img
            src={fields[i].image?.url}
            alt={fields[i].image?.alt}
            className="w-full object-contain"
          />
        </div>
        <div
          className={cn(
            'absolute top-half h-480 w-265 -translate-y-half transform',
            allActive() === -1 ? 'visible' : 'invisible'
          )}
          ref={(el: HTMLDivElement) => (imageRefs.current[i + 1] = el)}
        >
          <StyledLogo />
          <img
            src={fields[i].image?.url}
            alt={fields[i].image?.alt}
            className={cn('w-full object-contain')}
          />
        </div>
      </>
    )
  } else {
    return (
      <div
        className="invisible absolute top-0 h-480 w-265 -translate-y-half transform"
        ref={(el: HTMLDivElement) => (imageRefs.current[i] = el)}
      >
        <StyledLogo />
        <img
          src={fields[i].image?.url}
          alt={fields[i].image?.alt}
          className="w-full object-contain"
        />
      </div>
    )
  }
})

interface DesktopProps {
  primary: PageBodyClaims_With_AnimationPrimary
  fields: PageBodyClaims_With_AnimationFields[]
  windowSize: { width: number; height: number }
}

const ClaimsWithAnimationDesktop: FC<PropsWithChildren<DesktopProps>> = memo(
  ({ primary: { cta, cta_icon }, fields, windowSize }) => {
    const imageRefs = useRef<any[]>([])

    const [hasLoaded, setHasLoaded] = useState(false)
    const [imagePos, setImagePos] = useState(0)
    const [breakpoints, setBreakpoints] = useState<any[]>([])

    function getCoords(elem: HTMLElement) {
      const box = elem.getBoundingClientRect()

      const body = document.body
      const docEl = document.documentElement

      const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
      const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

      const clientTop = docEl.clientTop || body.clientTop || 0
      const clientLeft = docEl.clientLeft || body.clientLeft || 0

      const top = box.top + scrollTop - clientTop
      const left = box.left + scrollLeft - clientLeft

      return { top: top, left: left }
    }

    const allActive = () => {
      if (imagePos < breakpoints[0]?.top) {
        return 0
      } else if (imagePos >= breakpoints[7]?.top) {
        return -1
      } else {
        return 1
      }
    }

    useEffect(() => {
      setBreakpoints(imageRefs.current?.map(item => item && getCoords(item)))
    }, [imageRefs.current, windowSize, imagePos]) // imagePos included to (hopefully) fix wrong breakpoints in Safari, Firefox and sometimes Chrome

    useEffect(() => {
      hasLoaded && setTimeout(() => window.location.reload())
    }, [windowSize.width])

    useEffect(() => {
      !hasLoaded && setHasLoaded(true)
    }, [hasLoaded])

    useEffect(() => {
      function handleScroll() {
        setImagePos(window.pageYOffset + windowSize?.height / 2 - 240)
        setBreakpoints(imageRefs.current?.map(item => item && getCoords(item)))
      }
      setBreakpoints(imageRefs.current?.map(item => item && getCoords(item)))
      setImagePos(window.pageYOffset + windowSize?.height / 2 - 240)
      window.addEventListener('scroll', () => handleScroll())
      return () => window.removeEventListener('scroll', () => handleScroll())
    }, [windowSize])

    return (
      <div className="relative hidden grid-cols-2 md:grid">
        {fields
          .map(field => field.image)
          .map((image, i) => {
            const active = () => {
              if (i === 0) {
                return imagePos < breakpoints[i + 1]?.top
              } else if (i === fields.length - 1) {
                return imagePos >= breakpoints[i]?.top
              } else {
                return imagePos >= breakpoints[i]?.top && imagePos < breakpoints[i + 1]?.top
              }
            }
            return (
              <MovingImage
                image={image}
                key={i}
                breakpoints={breakpoints}
                active={active()}
                allActive={allActive()}
                i={i}
              />
            )
          })}
        {fields.map((field, i) => (
          <Fragment key={i}>
            <div className="relative flex min-h-480 items-center justify-center">
              <StaticImage fields={fields} imageRefs={imageRefs} i={i} allActive={allActive} />
            </div>
            <div className="my-160 h-min-content max-w-400 space-y-32">
              <Label className="ml-4">{field.label}</Label>
              <h2 className="font-normal">{field.heading}</h2>
              <div className="text-17">
                <RichText render={field.claim} />
              </div>
            </div>
          </Fragment>
        ))}
        <div></div>
        <div className="richtext-link -mt-180 flex items-center font-heading text-13 leading-5">
          <img src={cta_icon?.url} alt={cta_icon?.alt} className="-ml-10" />
          <div className="mt-5">
            <RichText render={cta} />
          </div>
        </div>
      </div>
    )
  }
)

interface MobileProps {
  primary: PageBodyClaims_With_AnimationPrimary
  fields: PageBodyClaims_With_AnimationFields[]
}

const ClaimsWithAnimationMobile: FC<PropsWithChildren<MobileProps>> = ({
  primary: { cta, cta_icon },
  fields,
}) => (
  <div className="flex flex-col items-center space-y-100 md:hidden ">
    {fields.map((field, i) => (
      <div key={i} className="flex max-w-300 flex-col items-center text-center sm:max-w-400">
        <Label className="ml-4 mb-24">{field.label}</Label>
        <StyledLogo className="w-120" />
        <img
          src={field.image?.url}
          alt={field.image?.alt}
          className="-mb-50 w-120 object-contain sm:-mb-80 sm:w-200"
        />
        <h2 className="mb-20 text-36 font-normal xxs:text-40 sm:text-48">{field.heading}</h2>
        <div className="text-15 sm:text-17">
          <RichText render={field.claim} />
        </div>
      </div>
    ))}
    <div className="richtext-link flex max-w-250 items-center font-heading text-12 leading-4 sm:max-w-full sm:text-13">
      <img src={cta_icon?.url} alt={cta_icon?.alt} className="-ml-10" />
      <RichText render={cta} />
    </div>
  </div>
)

interface MainProps {
  primary: PageBodyClaims_With_AnimationPrimary
  fields: PageBodyClaims_With_AnimationFields[]
}

export const ClaimsWithAnimation: FC<PropsWithChildren<MainProps>> = ({ primary, fields }) => {
  const windowSize = useWindowSize()

  return (
    <SectionMain>
      <ClaimsWithAnimationMobile primary={primary} fields={fields} />
      <ClaimsWithAnimationDesktop
        primary={primary}
        fields={fields}
        windowSize={windowSize as DesktopProps['windowSize']}
      />
    </SectionMain>
  )
}
