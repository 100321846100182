import { useEffect, useState } from 'react'
import { PageBodyHero, PageBodyHeroPrimary } from 'types/graphql'
import { Button, Label, SectionMain } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import cn from 'classnames'
import { isWindows } from 'utils/util'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import { Maybe } from 'types/graphql'

const MovingFire = dynamic<{}>(
  () => import('components/Hero/MovingFire').then(mod => mod.MovingFire),
  { ssr: false }
)

// anchor is passed from prismic, cta link field in prismic must be empty
const scrollToAnchor = (anchor: Maybe<string> | undefined) => {
  if (typeof window !== 'undefined' && anchor) {
    const element = document.getElementById(anchor) as HTMLElement

    const stickyMenuOffset = 100
    window.scrollTo({
      behavior: 'smooth',
      top: element?.getBoundingClientRect().top + window.scrollY - stickyMenuOffset,
    })
  }
}

const AboutUsHero = (props: PageBodyHeroPrimary) => {
  return (
    <SectionMain className="relative my-100 lg:pb-150">
      <div className="relative pt-100">
        <Label>{props.label}</Label>
        <h1 className="heading-strong text-stroke ginto-nord my-30 font-heading text-transparent xs:text-6xl xl:text-100 xxl:text-110">
          <RichText render={props.title} />
        </h1>
        <p className="pb-80 text-22 md:max-w-300 lg:max-w-400 lg:text-32">
          <RichText render={props.claim} />
        </p>
      </div>
      <div className="md:-translate-x left-half -z-1 w-screen transform pb-65 md:absolute md:top-half md:w-auto lg:max-w-650">
        <ParallaxWrapper deflection={40} breakpoint="md">
          <img
            className="relative h-full w-full md:-translate-y-half md:transform"
            src={props.image?.url}
            alt={props.image?.alt}
          />
        </ParallaxWrapper>
      </div>
      <div className="grid grid-cols-2 md:max-w-300 md:gap-x-64 lg:max-w-400 xl:max-w-600 xl:gap-x-32 xxl:gap-x-64">
        <div className="mb-30">
          <div className="text-stroke font-heading text-42 leading-none text-white md:text-48 lg:text-60 xl:text-76 xxl:text-88">
            <strong>{props.numbers[0].text}</strong>
          </div>
          <div className="mb-20 text-14 md:max-w-none md:text-16">Apps developed</div>
        </div>
        <div className="mb-30">
          <div className="text-stroke font-heading text-42 leading-none text-white md:text-48 lg:text-60 xl:text-76 xxl:text-88">
            <strong>{props.numbers[1].text}</strong>
          </div>
          <div className="mb-20 text-14 md:max-w-none md:text-16">Applifters</div>
        </div>
        <div className="mb-30">
          <div className="text-stroke font-heading text-42 leading-none text-white md:text-48 lg:text-60 xl:text-76 xxl:text-88">
            <strong>{props.numbers[2].text}</strong>
          </div>
          <div className="mb-20 text-14 md:max-w-none md:text-16">Clients</div>
        </div>
        <div className="mb-30">
          <div className="text-stroke font-heading text-42 leading-none text-white md:text-48 lg:text-60 xl:text-76 xxl:text-88">
            <strong>{props.numbers[3].text}</strong>
          </div>
          <div className="mb-20 text-14 md:max-w-none md:text-16">Years on market</div>
        </div>
      </div>
    </SectionMain>
  )
}

const CareersHero = (props: PageBodyHeroPrimary) => {
  return (
    <section className="w-full pt-120 pb-50 sm:pt-170 md:pt-220 lg:pb-30">
      <div className="flex w-full flex-col items-center">
        <div className="w-full px-15 lg:px-100">
          <ParallaxWrapper deflection={60}>
            <h1 className="lead heading-strong text-stroke ginto-nord relative mx-auto w-full max-w-1280 font-heading text-34 text-transparent xxs:text-40 sm:text-64 md:text-88 xxl:text-110">
              {props.title[0].text
                .substring(0, props.title[0].spans[props.title[0].spans.length - 1].start)
                .split('\n')
                .map((range: string, i: number) => (
                  <div key={i}>{range}</div>
                ))}
              <strong className="absolute left-0 top-full w-full">
                {props.title[0].text.substring(
                  props.title[0].spans[props.title[0].spans.length - 1].start,
                  props.title[0].spans[props.title[0].spans.length - 1].end
                )}
              </strong>
            </h1>
          </ParallaxWrapper>
        </div>
        <img
          className="relative -z-1 w-screen max-w-1480 xl:-mt-64"
          src={props.image?.url}
          alt={props.image?.alt}
        />
      </div>
    </section>
  )
}

const ServicesHero = (props: PageBodyHeroPrimary) => {
  return (
    <SectionMain className="relative lg:pb-50">
      <div className="absolute left-half mt-30 w-3/4 max-w-2/3 -translate-x-1/2 transform lg:w-1/2 lg:max-w-full">
        <img className="h-full w-full" src={props.image?.url} alt={props.image?.alt} />
      </div>
      <ParallaxWrapper deflection={30}>
        <div className="ssm:pt-200 relative pt-150">
          <Label>{props.label}</Label>
          <h1 className="heading-strong text-stroke ginto-nord my-30 font-heading text-40 text-transparent lg:text-6xl xl:text-110">
            <RichText render={props.title} />
          </h1>
          <div className="space-y-48 lg:flex lg:flex-row-reverse lg:justify-between lg:space-y-0">
            {!!RichText.asText(props.claim)?.length && (
              <p className="mt-18 max-w-550 text-30 lg:mt-0 lg:text-36 xl:max-w-640 xl:text-42">
                <RichText render={props.claim} />
              </p>
            )}
            <Button
              href={props.cta_link ? props.cta_link : null}
              type="link"
              noWrap
              customClass="uppercase w-min-content h-fit-content lg:mr-auto"
              center
              onClick={props.cta_anchor ? () => scrollToAnchor(props.cta_anchor) : undefined}
            >
              {props.cta_label}
            </Button>
          </div>
        </div>
      </ParallaxWrapper>
    </SectionMain>
  )
}

const LandingHero = (props: PageBodyHero) => {
  const [isWindowsOS, setIsWindowsOS] = useState<boolean | null>(null)

  useEffect(() => {
    setIsWindowsOS(isWindows())
  }, [])

  return (
    <SectionMain className="relative my-100 lg:pb-150">
      <div className="absolute left-half w-full -translate-x-1/2 transform md:w-3/4 md:translate-y-100 lg:w-7/12 lg:translate-y-50">
        <MovingFire />
        <ParallaxWrapper deflection={-50} className="block lg:hidden">
          <Image
            className="-z-1 mt-120 sm:mt-60 md:mt-120"
            width={props.primary?.image?.dimensions?.width}
            height={props.primary?.image?.dimensions?.height}
            src={props.primary?.image?.url}
            alt={props.primary?.image?.alt}
          />
        </ParallaxWrapper>
      </div>
      <div className="relative lg:mt-100">
        <h1
          className={cn(
            'heading-strong text-stroke ginto-nord relative font-heading text-54 text-transparent xs:text-60 sm:text-68 md:text-72 lg:text-88 xl:text-130',
            isWindowsOS ? 'mt-22 mb-18 lg:mt-14 lg:mb-24 xl:mt-8 xl:mb-30' : 'mt-30 mb-8'
          )}
        >
          <RichText render={props.primary?.title} />
        </h1>
        <Button
          type="link"
          noWrap
          href={props.primary?.cta_link}
          customClass="w-min-content max-h-20"
          center
        >
          {props.primary?.cta_label}
        </Button>
        <div className="relative float-right mt-250 mb-70 w-3/4 text-22 xxs:mt-300 xs:mt-350 sm:mb-100 sm:mt-200 md:mb-180 md:w-1/2 lg:mb-300 lg:w-2/5 lg:text-32 xl:text-42">
          <RichText render={props.primary?.claim} />
        </div>
      </div>
      <div className="relative z-10 flex w-full flex-col md:mx-auto md:flex-row">
        <div className="mx-auto my-50 px-15 xxs:px-30 md:mx-0 md:flex md:max-w-230 md:items-center">
          <Label>
            Came for the business.
            <br />
            Stayed for the people.
          </Label>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:flex-1 md:grid-cols-4 md:grid-rows-2">
          {props.fields?.map((field, i) => (
            <div className="mx-auto flex h-80 w-full max-w-160 items-center justify-center" key={i}>
              <div className="w-full">
                <Image
                  src={field.image.url}
                  alt={field.image.alt}
                  width={160}
                  height={80}
                  layout="responsive"
                  objectFit="contain"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </SectionMain>
  )
}

export const Hero = (props: { data: PageBodyHero }) => {
  switch (props.data.primary?.hero_type) {
    case 'about_us':
      return <AboutUsHero {...props.data.primary} />
    case 'careers':
      return <CareersHero {...props.data.primary} />
    case 'landing':
      return <LandingHero {...props.data} />
    default:
      return <ServicesHero {...props.data.primary} />
  }
}
