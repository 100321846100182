import {
  PageBodyCta_Claims_With_ImageFields,
  PageBodyCta_Claims_With_ImagePrimary,
} from 'types/graphql'
import { RichText } from 'prismic-reactjs'
import { Button } from 'components/Shared'
import Fade from 'react-reveal'
import cn from 'classnames'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import Image from 'next/image'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  primary: PageBodyCta_Claims_With_ImagePrimary
  fields: PageBodyCta_Claims_With_ImageFields[]
}

export const CtaClaimsWithImage: FC<PropsWithChildren<Props>> = ({
  primary: { heading_strong, claim, image, cta_label, cta_link, position_left },
  fields,
}) => {
  return (
    <section className="w-full xl:py-50">
      <Fade>
        <>
          <div className="my-50 mb-80 flex w-full px-15 lg:my-100 lg:mb-30 lg:px-80 xl:mb-100">
            <div className="mx-auto flex w-full max-w-1280 flex-col">
              <div className="lg:flex lg:justify-between lg:space-x-50">
                <ParallaxWrapper deflection={40} breakpoint="lg">
                  <div
                    className={cn(
                      'mb-64 flex flex-col text-left lg:w-min-content lg:items-start lg:text-left',
                      position_left ? 'items-start' : 'items-center text-center'
                    )}
                  >
                    <div className="h2-larger text-stroke mb-36 max-w-9/10 font-heading text-white lg:max-w-full">
                      <RichText render={heading_strong} />
                    </div>
                    <div className="mb-36 max-w-350 space-y-20 text-17 leading-9 sm:max-w-650 lg:mb-80 lg:max-w-500">
                      <RichText render={claim} />
                    </div>
                    <Button type="link" href={cta_link} noWrap={!!position_left} center>
                      {cta_label}
                    </Button>
                  </div>
                </ParallaxWrapper>
                <div className="mx-auto max-w-630">
                  <Image
                    src={image?.url}
                    width={image?.dimensions?.width}
                    height={image?.dimensions?.height}
                    className="w-full max-w-630 object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
          {fields.length === 3 && (
            <div className="mx-auto hidden w-full max-w-1660 flex-shrink items-start justify-between space-x-100 lg:mb-64 lg:flex lg:max-h-400 xl:max-h-500">
              {fields.map((field, i) => (
                <ParallaxWrapper
                  className="max-h-full min-w-0 max-w-400"
                  deflection={(i + 1) * 20}
                  breakpoint="lg"
                  key={i}
                >
                  <Image
                    src={field.image?.url}
                    alt={field.image?.alt}
                    width={field.image?.dimensions?.width}
                    height={field.image?.dimensions?.height}
                    className={cn('object-contain', i !== 1 && 'lg:mt-50 xl:mt-60')}
                  />
                </ParallaxWrapper>
              ))}
            </div>
          )}
        </>
      </Fade>
    </section>
  )
}
