import { useState } from 'react'
import { PageBodyPartnershipFields } from 'types/graphql'
import Plus from 'assets/icons/menu_plus.svg'
import Minus from 'assets/icons/menu_minus.svg'
import AnimateHeight from 'react-animate-height'
import cn from 'classnames'
import Image from 'next/image'

export const Partner = (props: {
  partner: PageBodyPartnershipFields
  index: number
  length: number
}) => {
  const [toggleActive, setToggleActive] = useState(false)

  const handleClick = () => {
    setToggleActive(!toggleActive)
  }

  return (
    <li
      className={cn(
        'cursor-pointer border-alto py-40 md:py-50',
        props.index === props.length - 1 ? 'border-b-0' : 'border-b'
      )}
      onClick={handleClick}
    >
      <div className="relative flex flex-col md:flex-row md:items-center">
        <div className="w-140">
          <Image
            src={props.partner.image.url}
            alt="logo"
            width={120}
            height={40}
            layout="responsive"
            objectFit="contain"
            objectPosition="left"
          />
        </div>
        <div className="pt-32 text-14 uppercase tracking-extra-widest md:pl-140 md:pt-0 lg:pl-40 xl:pl-140">
          {props.partner.name}
        </div>
        <div className="absolute right-0">{toggleActive ? <Minus /> : <Plus />}</div>
      </div>
      <AnimateHeight duration={500} height={toggleActive ? 'auto' : 0}>
        <div className={'mt-50 flex justify-end text-17 leading-8'}>
          <div className="w-full md:pl-280 lg:pl-180 xl:pl-280">{props.partner.description}</div>
        </div>
      </AnimateHeight>
    </li>
  )
}
