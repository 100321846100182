import { FC, useState } from 'react'
import { PageBodyGrid_TextsFields, PageBodyGrid_TextsPrimary } from 'types/graphql'
import { RichText } from 'prismic-reactjs'
import cn from 'classnames'
import Chevron from 'assets/icons/chevron.svg'
import AnimateHeight from 'react-animate-height'
import Fade from 'react-reveal'
import type { PropsWithChildren } from 'react'

interface GridTextsProps {
  primary: PageBodyGrid_TextsPrimary
  fields: PageBodyGrid_TextsFields[]
}

interface GridItemTextProps {
  heading: PageBodyGrid_TextsFields['heading_multi']
  claim: PageBodyGrid_TextsFields['claim']
}

interface GridItemImageProps {
  image: PageBodyGrid_TextsFields['image']
  colSpan: PageBodyGrid_TextsFields['col_span']
}

const GridItemText: FC<PropsWithChildren<GridItemTextProps>> = ({ heading, claim }) => {
  const [height, setHeight] = useState<number | 'auto'>(0)

  const handleClick = () => {
    height ? setHeight(0) : setHeight('auto')
  }
  return (
    <>
      <div className="block md:hidden">
        <div className="relative cursor-pointer" onClick={handleClick}>
          <div className="mb-10 flex min-h-65 max-w-140 flex-col justify-end text-20 xs:text-22 sm:mb-24 sm:max-w-full sm:text-26">
            <RichText render={heading} />
          </div>
          <AnimateHeight height={height}>
            <div className={cn({ 'mb-16': height })} style={{ transition: 'margin 250ms ease' }}>
              <RichText render={claim} />
            </div>
          </AnimateHeight>
          <div className="absolute flex -translate-y-4 transform items-center space-x-8 sm:-translate-y-12">
            <div className="w-120 border-b border-black xs:w-140"></div>
            <Chevron className={cn('w-12 transform', { 'rotate-180': height })} />
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        <div className="mb-10 flex min-h-65 flex-col justify-end text-20 xs:text-22 sm:mb-24 sm:text-26">
          <RichText render={heading} />
        </div>
        <RichText render={claim} />
      </div>
    </>
  )
}

const GridItemImage: FC<PropsWithChildren<GridItemImageProps>> = ({ image, colSpan }) => {
  return (
    <img
      src={image.url}
      alt={image.alt}
      className={cn(
        'lg:max-w-auto max-h-150 transform object-contain lg:absolute lg:left-0 lg:top-half lg:max-h-1000 lg:w-auto lg:-translate-y-half xxl:min-w-310',
        colSpan === '1'
          ? 'lg:max-w-220 lg:-translate-x-45 xl:max-w-250 xxl:max-w-300 xxl:-translate-x-90'
          : 'lg:max-w-310 lg:translate-x-16 xl:max-w-350 xl:-translate-x-0'
      )}
    />
  )
}

export const GridTexts: FC<PropsWithChildren<GridTextsProps>> = ({
  primary: { bg_color, heading_strong },
  fields,
}) => {
  return (
    <section
      className="w-full max-w-1480 py-64 px-16 lg:my-100 lg:px-64 xl:my-120"
      style={{ background: bg_color as string }}
    >
      <Fade>
        <div className="mx-auto grid max-w-1200 grid-cols-2 gap-y-32 gap-x-24 sm:gap-x-0 sm:gap-y-50 md:grid-cols-3 md:gap-y-64 lg:grid-cols-5 lg:gap-y-100 lg:gap-x-32 xl:gap-y-120 xl:gap-x-40">
          <div className="h2-large text-stroke col-span-2 font-heading text-transparent children:text-40 sm:children:text-64 md:col-span-3 lg:col-span-3 lg:mb-0 lg:w-auto">
            <RichText render={heading_strong} />
          </div>
          {fields.map((field, i) => (
            <div
              key={i}
              className={cn(
                'w-full justify-self-center lg:justify-self-start',
                field.item_type === 'image'
                  ? 'relative flex max-w-full justify-center'
                  : 'max-w-180',
                {
                  'lg:col-span-2': field.col_span === '2',
                }
              )}
            >
              {field.item_type === 'text' ? (
                <GridItemText heading={field.heading_multi} claim={field.claim} />
              ) : (
                <GridItemImage image={field.image} colSpan={field.col_span} />
              )}
            </div>
          ))}
        </div>
      </Fade>
    </section>
  )
}
