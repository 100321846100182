import { CaseStudySliderItem } from 'components/CaseStudy/CaseStudySliderItem'
import { FC, useRef } from 'react'
import { Case_StudyConnectionEdge } from 'types/graphql'
import { linkResolver } from 'utils'
import ScrollContainer from 'react-indiana-drag-scroll'
import { ScrollTo } from 'react-scroll-to'
import { SliderArrows } from 'components/CaseStudy/SliderArrows'
import { Button, SectionCaseStudies } from 'components/Shared'
import type { PropsWithChildren } from 'react'

interface Props {
  caseStudies: Case_StudyConnectionEdge[]
}

export const CaseStudiesSlider: FC<PropsWithChildren<Props>> = ({ caseStudies }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  return (
    <SectionCaseStudies>
      <ScrollTo>
        {({ scroll }) => (
          <>
            <div className="h2-larger text-stroke adjusted-container font-heading text-white">
              <h2>Our Work</h2>
            </div>
            <ScrollContainer innerRef={scrollContainerRef} className="overflow-x-auto">
              <ul className="my-24 flex gap-16 sm:my-40 sm:gap-32 md:my-64 md:gap-64">
                <div className="filler"></div>
                {caseStudies.map(caseStudy => {
                  return (
                    <CaseStudySliderItem
                      title={caseStudy.node.title!}
                      description={caseStudy.node.description!}
                      image={caseStudy.node.image}
                      key={caseStudy.node.title}
                      url={linkResolver(caseStudy.node)}
                    />
                  )
                })}
                <div className="filler"></div>
              </ul>
            </ScrollContainer>
            <div className="adjusted-container flex  flex-row justify-between align-middle">
              <Button href={'/our-work'} type={'link'} center={true}>
                SEE ALL
              </Button>
              <SliderArrows scrollToFunction={scroll} scrollContainerRef={scrollContainerRef} />
            </div>
          </>
        )}
      </ScrollTo>
    </SectionCaseStudies>
  )
}
