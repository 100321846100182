import { PageBodyCentered_TextPrimary } from 'types/graphql'
import { RichText } from 'prismic-reactjs'
import { Label, SectionMain } from 'components/Shared'
import Fade from 'react-reveal'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  data: PageBodyCentered_TextPrimary
}

export const CenteredText: FC<PropsWithChildren<Props>> = ({ data: { label, heading, claim } }) => (
  <SectionMain>
    <Fade bottom>
      <div className="flex flex-col lg:mx-auto lg:max-w-800 lg:items-center">
        <Label newLineAferColon={true} className="lg:text-center">
          {label}
        </Label>
        <div className="mt-32 lg:mt-60 lg:flex">
          <ParallaxWrapper deflection={10} breakpoint="lg" className="lg:flex-1">
            <h2 className="mb-48 font-light">{heading}</h2>
          </ParallaxWrapper>
          <div className="flex-1 text-17 leading-8 lg:ml-40 lg:mt-80 lg:flex-1">
            <RichText render={claim} />
          </div>
        </div>
      </div>
    </Fade>
  </SectionMain>
)
