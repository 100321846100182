import { PageBodyText_ColumnsFields } from 'types/graphql'
import { RichText } from 'prismic-reactjs'
import cn from 'classnames'
import { FC, Fragment } from 'react'
import type { PropsWithChildren } from 'react'

interface Props extends PageBodyText_ColumnsFields {
  i: number
  isColumn?: boolean | null
}

const addNewLines = (text: string) => {
  const splitText = text.split('\n')
  return splitText.map((line, i) => {
    if (i !== splitText.length - 1) {
      return (
        <Fragment key={i}>
          {line}
          <br />
        </Fragment>
      )
    }
    return line
  })
}

export const TextColumn: FC<PropsWithChildren<Props>> = ({ heading, claim, i, isColumn }) => {
  return (
    <div
      key={i}
      className={cn('m-auto mt-50', isColumn ? 'sm:ml-20 sm:mr-20' : 'sm:ml-50 sm:mr-50')}
    >
      <div className={cn('mb-30 flex', isColumn ? 'flex-col' : 'flex-row')}>
        <div
          className={cn(
            'mt-5 mr-40 tracking-extra-widest sm:mr-20',
            isColumn ? 'mb-50 text-14' : 'xs:-ml-10 sm:-ml-40'
          )}
        >
          {'0' + (i + 1)}
        </div>
        <h1
          className={cn(
            'max-w-200 font-normal leading-9 sm:max-w-300',
            isColumn ? 'text-32' : 'text-36 sm:text-48'
          )}
        >
          {addNewLines(heading as string)}
        </h1>
      </div>
      <div className={cn('ml-60 max-w-300 leading-8 xs:ml-50 sm:m-0', isColumn && 'text-17')}>
        <RichText render={claim} />
      </div>
    </div>
  )
}
