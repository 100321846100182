import ChevronRight from 'assets/icons/chevron_full_right.svg'
import Check from 'assets/icons/check.svg'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { ArticleBodyCustom_Subscription } from 'types/graphql'
import Image from 'next/image'
import { FC } from 'react'
import { emailRegex } from 'utils'
import getErrorMessage from 'utils/getErrorMessage'
import type { PropsWithChildren } from 'react'

const defaultValues = {
  email: '',
  serverError: '',
}

export const SubscribeNewsletter: FC<PropsWithChildren<ArticleBodyCustom_Subscription>> = props => {
  const { register, handleSubmit, setError, errors, formState } = useForm({
    defaultValues,
  })

  const onSubmit = handleSubmit(async data => {
    try {
      await axios.post('/api/subscribe', {
        email: data.email,
        list: props.primary?.list_id,
      })
    } catch (error) {
      setError('serverError', {
        type: 'server',
        message: getErrorMessage(error),
      })
    }
  })

  return (
    <section
      className={cn(
        'mx-auto flex w-full px-15 py-50 lg:px-80 lg:py-100',
        props.primary?.background ? 'max-w-1100' : 'max-w-1480'
      )}
      style={{ background: `${props.primary?.background}` }}
    >
      <div className="flex w-full flex-col">
        <div className="mx-auto flex flex-col justify-start md:mx-0 md:flex-row md:items-center md:justify-between xl:mx-100">
          {props.primary?.image ? (
            <div className="mx-auto w-full max-w-320 md:mx-0">
              <Image
                src={props.primary.image.url}
                alt={props.primary.image.alt}
                width={320}
                height={450}
                layout="responsive"
                objectFit="contain"
              />
            </div>
          ) : (
            <h2
              className={cn(
                'mb-40 font-body font-normal tracking-wider md:mb-0',
                props.primary?.title ? 'max-w-400 text-26 sm:max-w-250' : 'max-w-550 lg:text-76'
              )}
            >
              {props.primary?.title || 'Join our newsletter'}
            </h2>
          )}
          <div className="flex max-w-400 flex-col">
            {props.primary?.image && (
              <h2 className="mb-40 font-body text-42 font-normal tracking-wider">
                {props.primary?.title}
              </h2>
            )}
            <form onSubmit={onSubmit}>
              <div className="flex">
                <input
                  name="email"
                  placeholder="Email"
                  ref={register({
                    required: 'This field is required',
                    pattern: {
                      value: emailRegex,
                      message: 'Entered value does not match email format',
                    },
                  })}
                  className={cn(
                    'w-230 border-black200 text-24 sm:w-350',
                    props.primary?.background ? 'placeholder-black300' : 'placeholder-cloud'
                  )}
                />
                <button
                  className={cn(
                    'border border-black100 text-black100 outline-none transition-all duration-500',
                    !formState.isSubmitSuccessful
                      ? 'hover:bg-black100 hover:text-white'
                      : 'cursor-default'
                  )}
                  type="submit"
                >
                  <div className={cn(formState.isSubmitSuccessful ? 'px-12 py-16' : 'px-19 py-19')}>
                    {formState.isSubmitSuccessful ? <Check /> : <ChevronRight />}
                  </div>
                </button>
              </div>
              {errors.email && <div className="text-12 text-red">{errors.email.message}</div>}
              {errors.serverError && (
                <div className="text-12 text-red">{errors.serverError.message}</div>
              )}
              {formState.isSubmitSuccessful && (
                <div className="text-12 text-green">You are successfully subscribed</div>
              )}
            </form>

            <p className="pt-10 text-12 text-black300 md:pr-40">
              By clicking the button I agree with the collection and processing of my personal data
              as described in the Privacy policy
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
