import { Job_OfferBodyHeroPrimary } from 'types/graphql'
import { SectionMain } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import { ButtonBack } from 'components/Shared/ButtonBack'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import { ROUTES } from 'utils'
import Image from 'next/image'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  data: Job_OfferBodyHeroPrimary
}

export const HeroJobOffer: FC<PropsWithChildren<Props>> = ({
  data: { image, title, claim, paragraph },
}) => {
  return (
    <>
      <ButtonBack link={ROUTES.careers} label="Careers" />
      <SectionMain className="relative my-100 mt-20 pt-100 lg:pt-0 lg:pb-150">
        <div className="mx-auto lg:max-w-1000">
          <div className="absolute top-150 left-half -translate-x-half transform lg:w-1/2">
            <div className="h-full w-full">
              <Image
                src={image?.url}
                alt={image?.alt}
                width={740}
                height={720}
                layout="responsive"
                objectFit="contain"
              />
            </div>
          </div>
          <ParallaxWrapper deflection={40} breakpoint="lg">
            <div className="relative pt-90 lg:pt-200">
              <h1 className="heading-strong text-stroke my-30 font-heading text-32 text-transparent xxs:text-36 xs:text-40 md:text-6xl lg:text-110">
                <RichText render={title} />
              </h1>
              <div className="flex flex-col xs:max-w-9/10 lg:w-full lg:flex-row lg:items-center lg:justify-between">
                <h2 className="mb-24 text-30 font-light lg:max-w-500 lg:text-48">
                  <RichText render={claim} />
                </h2>
                <div className="lg:max-w-400 lg:text-17">
                  <RichText render={paragraph} />
                </div>
              </div>
            </div>
          </ParallaxWrapper>
        </div>
      </SectionMain>
    </>
  )
}
