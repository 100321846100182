import { Microsite_SubpageBodyMicrosite_Subpage_HeroPrimary } from 'types/graphql'
import { Button, SectionMain } from 'components/Shared'
import { RichText } from 'prismic-reactjs'
import { ParallaxWrapper } from 'components/ParallaxWrapper/ParallaxWrapper'
import cn from 'classnames'
import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  data: Microsite_SubpageBodyMicrosite_Subpage_HeroPrimary
}

export const MicrositeSubpageHero: FC<PropsWithChildren<Props>> = ({
  data: { title, image, cta_label, cta_link, description },
}) => {
  const hasDescription = !!RichText.asText(description)?.length

  return (
    <SectionMain className="sm:pb-100 lg:pb-50">
      <ParallaxWrapper deflection={30}>
        <div
          className={cn('sm:pt-275 relative space-y-24 pt-50 xs:pt-150 sm:space-y-48', {
            'xl:space-y-380': hasDescription,
          })}
        >
          <h1 className="heading-strong text-stroke ginto-nord relative font-heading text-40 text-transparent sm:text-54 md:text-64 lg:text-88 xl:text-110">
            <div className="absolute right-0 top-1/4 -z-1 w-3/5 max-w-400 xs:top-half xs:-translate-y-half xs:transform lg:max-w-full">
              <ParallaxWrapper deflection={-30}>
                <img className="h-full w-full" src={image?.url} alt={image?.alt} />
              </ParallaxWrapper>
            </div>
            <RichText render={title} />
          </h1>
          <div className="xl:flex xl:flex-row xl:justify-between">
            <Button
              href={cta_link}
              type="link"
              customClass="uppercase items-center w-min-content h-fit-content xxs:whitespace-nowrap"
              fontSizeClasses="text-12 lg:text-16"
              noWrap
              whitespaceWrap
            >
              {cta_label}
            </Button>

            {hasDescription && (
              <div className="mt-105 max-w-720 text-22 xl:-mt-10 xl:text-42">
                <RichText render={description} />
              </div>
            )}
          </div>
        </div>
      </ParallaxWrapper>
    </SectionMain>
  )
}
