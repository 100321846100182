import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { useWindowSize } from 'utils/useWindowSize'
import type { PropsWithChildren } from 'react'

interface Props {
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl'
  deflection: number
  factor?: number
  start?: number
  noNeg?: boolean
  className?: string
  positionFix?: boolean
}

const calc = (offset: number) => `translateY(${offset}px)`

export const ParallaxWrapper: FC<PropsWithChildren<Props>> = ({
  breakpoint,
  deflection,
  factor,
  noNeg,
  className,
  positionFix,
  children,
}) => {
  const windowSize = useWindowSize()

  const getBreakpoint = () => {
    switch (breakpoint) {
      case 'sm':
        return 640
      case 'md':
        return 768
      case 'lg':
        return 1024
      case 'xl':
        return 1280
      default:
        return 0
    }
  }

  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null)

  const [{ offset }, set] = useSpring(() => ({
    offset: 100 * (deflection * 0.01) * (factor || 0.25),
  }))
  const handleScroll = () => {
    if (!ref.current) return
    const elScrollPosition = ref.current.getBoundingClientRect().top
    const offset =
      (positionFix ? -(window.pageYOffset - elScrollPosition) : elScrollPosition) *
      (deflection * 0.01) *
      (factor || 0.25)

    set.start(noNeg ? (offset > 0 ? { offset } : {}) : { offset })
  }

  // put style to state to ensure the props are the same on SSG and on client side.
  // On first render based on the window height is the style is set
  const [style, setStyle] = useState({})

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    setStyle(
      windowSize.width! > getBreakpoint() && offset
        ? { transform: offset.to(o => calc(o as number)) }
        : {}
    )

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (positionFix)
    return (
      <div ref={ref}>
        <animated.div className={className} style={style}>
          {children}
        </animated.div>
      </div>
    )

  return (
    <animated.div className={className} style={style} ref={ref}>
      {children}
    </animated.div>
  )
}
